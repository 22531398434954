import { CheckboxesFieldOption } from "../Forms/fields/CheckboxesField";
import { gruopItemProps } from "../Forms/fields/ToogleButtonGroup";

export const FINAL_RECOMENDATIONS: gruopItemProps[] = [
  {
    label: "Service Recommended Immediately",
    value: "Service Recommended Immediately",
  },
  {
    label: "Service Soon",
    value: "Service Soon",
  },
  {
    label: "All Systems Go",
    value: "All Systems Go",
  },
];

export const SERVICES: CheckboxesFieldOption[] = [
  {
    label: "Front Pads",
    value: "Front Pads",
  },
  {
    label: "Front Rotors",
    value: "Front Rotors",
  },
  {
    label: "Rear Pads",
    value: "Rear Pads",
  },
  {
    label: "Rear Rotors",
    value: "Rear Rotors",
  },
  {
    label: "Brake Fluid",
    value: "Brake Fluid",
  },
];

export const WITH_ESTIMATE_TIMELINE = ["All Systems Go", "Service Soon"];
