import gql from "graphql-tag";

export const GET_PRODUCTS = gql`
  query getProducts {
    getProducts {
      id
      amount
      description
      name
      laborCost
      partsCost
    }
  }
`;
