import React from "react";
import { PostJobCheckList } from "./types";
import { PostJobCheckListForm } from "./PostJobCheckListForm";
import { useMutation } from "@apollo/client";
import {
  Mutation,
  MutationUpdateInvoiceArgs,
  MutationUpdateJobArgs,
  UpdateJobInput,
} from "../../generated/nest-graphql";
import { UPDATE_JOB } from "../../graphql/mutations/updateJob";
import { UPDATE_INVOICE } from "../../graphql/mutations/updateInvoice";
import { flow } from "lodash";
import { postJobCheckListUpdateJobSpec } from "../Jobs/jobDetailsSpec";
import { keys } from "ramda";
import { showSuccessAlert } from "../../actions";
import { useDispatch } from "../../contexts/snackbar-context";
import { useInspectionReport } from "../../contexts/inspection-context";
import { PostJobCheckListFormCore } from "./PostJobCheckListFormCore";

export type PostJobCheckListFormValues = PostJobCheckList;

export const PostJobCheckListDialog = ({
  jobId,
  open,
  onClose,
  initialValues,
  parentSubmit,
  proceedingWithRepairs,
  isVioc,
}: {
  jobId: string;
  open: boolean;
  onClose: () => void;
  initialValues: PostJobCheckListFormValues;
  parentSubmit: () => void;
  proceedingWithRepairs?: boolean;
  isVioc?: boolean;
}) => {
  const dispatch = useDispatch();
  const [updateJob] = useMutation<Mutation, MutationUpdateJobArgs>(UPDATE_JOB);
  const { state } = useInspectionReport();
  const onSubmit = async (values: PostJobCheckListFormValues) => {
    const input: UpdateJobInput = flow(postJobCheckListUpdateJobSpec)(
      values as PostJobCheckListFormValues,
      proceedingWithRepairs
    );
    await updateJob({
      variables: {
        id: jobId,
        updateJobInput: input,
      },
    });
    parentSubmit();
    onClose();
    showSuccessAlert(dispatch, "Success");
  };

  if (!isVioc) {
    return (
      <PostJobCheckListFormCore
        key={"pre-job-2"}
        onSubmit={onSubmit}
        open={open}
        onClose={onClose}
        initialValues={initialValues}
      />
    );
  }

  return (
    <PostJobCheckListForm
      key={"pre-job-2"}
      onSubmit={onSubmit}
      open={open}
      onClose={onClose}
      jobId={jobId}
      initialValues={initialValues}
      proceedingWithRepairs={proceedingWithRepairs}
    />
  );
};
