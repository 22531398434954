import * as React from "react";
import { FullScreenDialog } from "../FullScreenDialog";
import { UpdateInvoiceDetailsFormValues } from "../Forms/UpdateInvoiceDetailsForm";
import { AddInvoiceDetailsForm } from "../Forms/AddInvoiceDetailsForm";

import { DEFAULT_SERVICE_CATALOGUE_USED } from "../../lib/constants";
import { useInvoice } from "../../hooks/useInvoice";

export const AddInvoiceDialog: React.FC<{
  initialValues?: UpdateInvoiceDetailsFormValues;
  open: boolean;
  onClose: () => void;
  jobId: string;
}> = ({ initialValues, open, onClose, jobId }) => {
  const { createJobInvoice } = useInvoice();
  const onSubmit = async (values: UpdateInvoiceDetailsFormValues) => {
    createJobInvoice(jobId, values);
  };

  return (
    <FullScreenDialog title={"Add Invoice"} open={open} onClose={onClose}>
      <AddInvoiceDetailsForm
        initialValues={
          initialValues
            ? initialValues
            : // seems like duplicate with [id].tsx guessing this is formik...
              {
                status: "Draft",
                contact: null,
                serviceLocation: "",
                market: "",
                technician: null,
                items: [],
                issuedDate: new Date(),
                dueDate: new Date(),
                services: [],
                discounts: [],
                promoCodes: [],
                serviceCatalogueUsed: DEFAULT_SERVICE_CATALOGUE_USED,
                serviceFollowUp: {
                  followUpNeeded: false,
                  outreachTimeline: null,
                  outreachDate: null,
                  outreachNotes: null,
                },
                proceedingWithRepairs: false,
              }
        }
        onSubmit={onSubmit}
      />
    </FullScreenDialog>
  );
};
