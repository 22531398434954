import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Link } from "@material-ui/core";
import currency from "currency.js";
import { Form, FieldArray, Field } from "formik";
import { path, isNil, cond, equals, T, always } from "ramda";
import React from "react";
import { DateInput } from "../Forms/fields/DateInput";
import { TextField } from "../Forms/fields/TextField";
import CurrencyInput from "./fields/CurrencyInput";
import { SelectOptions } from "./fields/SelectOptions";
import DownArrow from "@material-ui/icons/ArrowDownward";
import UpArrow from "@material-ui/icons/ArrowUpward";
import Checkmark from "@material-ui/icons/Check";
import CheckCircle from "@material-ui/icons/CheckCircle";
import XCircle from "@material-ui/icons/Cancel";
import DeleteIcon from "@material-ui/icons/DeleteOutline";

const PaymentMethodsForm = ({
  payments,
  successfulPayments,
  balanceDue,
  amountDifference,
  amountReadOnly,
  setAddNewCardState,
  setBuyNowPayLaterState,
  cardOptions,
  isSubmitting,
  submitCount,
  initialPaymentValues,
}) => {
  const splitAmount = currency(balanceDue).distribute(2); //split the amount owed into two parts, distribute handles cents better
  const buyNowPayLaterOption = payments.length === 1 ? [{ label: "Buy Now Pay Later", value: "buyNowPayLater" }] : []; //not allowed with split payments
  const paymentOptions = [
    ...cardOptions,
    { label: "Cash", value: "Cash" },
    { label: "Check", value: "Check" },
    { label: "Add New Card", value: "addNewCard" },
    ...buyNowPayLaterOption,
  ];
  return (
    <Form>
      <FieldArray
        name="payments"
        render={(arrayHelpers) => {
          return (
            <>
              {payments.map((payment, index) => {
                const paymentRun = submitCount > 0 && !isSubmitting;
                const paymentSucceeded = successfulPayments.includes(index);
                return (
                  <>
                    <div className={`flex justify-between items-center  pb-1 ${index > 0 ? "pt-4" : ""}`}>
                      <div className="flex items-center">
                        <div className="!mr-2">
                          Payment {index + 1} {paymentRun && (paymentSucceeded ? "Succeeded" : "Failed")}
                        </div>
                        {paymentRun &&
                          (paymentSucceeded ? (
                            <CheckCircle className="text-green-500" />
                          ) : (
                            <XCircle color={"secondary"} />
                          ))}
                      </div>
                      {index === 1 && submitCount === 0 && successfulPayments.length === 0 && (
                        <Link
                          href="#"
                          onClick={() => {
                            arrayHelpers.pop();
                            arrayHelpers.replace(0, { ...payments[0], amount: balanceDue });
                          }}
                        >
                          <DeleteIcon />
                        </Link>
                      )}
                    </div>
                    <div
                      className="grid gap-4 p-4 mb-2"
                      style={{
                        border: "1px solid rgb(212, 212, 212)",
                        borderRadius: 10,
                      }}
                    >
                      <Field name={`payments.${index}.paymentMethod`}>
                        {({ field }) => (
                          <SelectOptions
                            className=""
                            {...field}
                            options={
                              index === 1 && successfulPayments.length === 0
                                ? paymentOptions.filter((val) => val.value !== payments[0].paymentMethod)
                                : paymentOptions
                            }
                            label={"Method"}
                            onChange={(event) => {
                              const value = path<string>(["target", "value"], event);
                              if (
                                index === 0 &&
                                payments.length > 1 &&
                                value === payments[1].paymentMethod &&
                                !successfulPayments.includes(1)
                              ) {
                                //handling one payment succeeding and one failing
                                arrayHelpers.replace(1, {
                                  ...initialPaymentValues,
                                  paymentMethod: null,
                                  amount: splitAmount[1].toString(),
                                });
                              }

                              cond([
                                [
                                  equals("addNewCard"),
                                  () =>
                                    setAddNewCardState({
                                      showDialog: true,
                                      setPaymentMethod: (paymentId) =>
                                        arrayHelpers.replace(index, { ...payment, paymentMethod: paymentId }),
                                    }),
                                ],
                                [
                                  equals("buyNowPayLater"),
                                  () =>
                                    setBuyNowPayLaterState((prevState) => ({
                                      ...prevState,
                                      setPaymentMethod: () =>
                                        arrayHelpers.replace(index, { ...payment, paymentMethod: "buyNowPayLater" }),
                                      showDialog: true,
                                    })),
                                ],
                                [T, (val) => arrayHelpers.replace(index, { ...payment, paymentMethod: val })],
                              ])(value);
                            }}
                            errorMessage={
                              isNil(payment.paymentMethod) ? "No payment method selected, please add a method." : ""
                            }
                            disabled={paymentSucceeded}
                          />
                        )}
                      </Field>
                      {payment?.paymentMethod === "Check" ? (
                        <div className="grid gap-4">
                          <DateInput name={`payments.${index}.receivedDate`} label={"Received Date"} required={true} />
                          <div className={"flex flex-row items-center"}>
                            <CurrencyInput
                              name={`payments.${index}.amount`}
                              label={"Amount"}
                              readOnly={amountReadOnly}
                              required={true}
                              variant="outlined"
                            />
                            {payments.length > 1 && (
                              <Button
                                className="!ml-2"
                                variant={"contained"}
                                color={"primary"}
                                startIcon={
                                  (amountDifference.value < 0 && <UpArrow />) ||
                                  (amountDifference.value > 0 && <DownArrow />) || <Checkmark />
                                }
                                onClick={() => {
                                  const newAmount = currency(payments[index].amount).subtract(amountDifference);
                                  const newAmountString = newAmount?.value > 0 ? newAmount.toString() : "";
                                  arrayHelpers.replace(index, {
                                    ...payments[index],
                                    amount: newAmountString,
                                  });
                                }}
                                disabled={amountDifference.value === 0}
                              >
                                {amountDifference.value === 0 ? "Amount matches" : "Adjust amount"}
                              </Button>
                            )}
                          </div>
                          <TextField name={`payments.${index}.refNumber`} label={"Ref #/Check #"} />
                          <TextField name={`payments.${index}.memo`} label={"Memo"} />
                        </div>
                      ) : (
                        <div className={"flex flex-row items-center"}>
                          <CurrencyInput
                            name={`payments.${index}.amount`}
                            label={"Amount"}
                            readOnly={amountReadOnly}
                            required={true}
                            variant="outlined"
                          />
                          {payments.length > 1 && (
                            <Button
                              className="!ml-2"
                              variant={"contained"}
                              color={"primary"}
                              startIcon={
                                (amountDifference.value < 0 && <UpArrow />) ||
                                (amountDifference.value > 0 && <DownArrow />) || <Checkmark />
                              }
                              onClick={() => {
                                const newAmount = currency(payments[index].amount).subtract(amountDifference);
                                const newAmountString = newAmount?.value > 0 ? newAmount.toString() : "";
                                arrayHelpers.replace(index, {
                                  ...payments[index],
                                  amount: newAmountString,
                                });
                              }}
                              disabled={amountDifference.value === 0}
                            >
                              {amountDifference.value === 0 ? "Amount matches" : "Adjust amount"}
                            </Button>
                          )}
                        </div>
                      )}
                    </div>
                  </>
                );
              })}
              <div className="flex justify-end">
                {payments.length < 2 && (
                  <Link
                    href="#"
                    onClick={() => {
                      arrayHelpers.push({
                        ...initialPaymentValues,
                        paymentMethod: "",
                        amount: splitAmount[1].toString(),
                      });
                      arrayHelpers.replace(0, { ...payments[0], amount: splitAmount[0].toString() });
                    }}
                    className="!no-underline"
                  >
                    <FontAwesomeIcon icon={faCut as IconProp} className="mr-1" />
                    Split Payment
                  </Link>
                )}
              </div>
            </>
          );
        }}
      />
    </Form>
  );
};

export default PaymentMethodsForm;
