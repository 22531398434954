import gql from "graphql-tag";
import { partsStoreDetailsFields } from "../queries/getPartsStore";

export const GET_PARTS_STORES = gql`
  query getPartsStores($filter: JSON) {
    getPartsStores(filter: $filter) {
      ${partsStoreDetailsFields}
    }
  }
`;
