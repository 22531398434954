import React, { useState } from "react";
import { EndAdornmentField, FieldObject, InputType } from "./fields";
import { TextField } from "../fields/TextField";
import { Select } from "../fields/Select";
import { Button, InputAdornment } from "@material-ui/core";
import { ColorGradeIcon } from "../../PreJobChecklistV2/colorGradeHelpers";
import { Field, useField, useFormikContext } from "formik";
import { SelectOptions } from "../fields/SelectOptions";
import { TextAreaField } from "../fields/TextAreaField";
import { CheckboxField } from "../fields/CheckboxField";
import { CheckCircle, Error } from "@material-ui/icons";
import { FilesDialog } from "../../Files/FilesDialog";
import { VIN_FILE_CATEGORY } from "../../PreJobChecklistV2/constants";

type Props = {
  field: FieldObject;
};

type PropsColorGrade = {
  endAdornment: EndAdornmentField;
  value: any;
};

const ColorGradeCompoent = ({ endAdornment, value }: PropsColorGrade) => {
  if (endAdornment.colorGradeType === "onlyValue") {
    return <ColorGradeIcon className="ml-2" colorGrade={value} />;
  }

  if (!endAdornment.colorGrade) return null;
  if (endAdornment.colorGradeType === "simpleFunction") {
    return <ColorGradeIcon className="ml-2" colorGrade={endAdornment.colorGrade(value)} />;
  }
  if (endAdornment.secondColorGradeParam) {
    return (
      <ColorGradeIcon
        className="ml-2"
        colorGrade={endAdornment.colorGrade(value, endAdornment.secondColorGradeParam)}
      />
    );
  }
  return null;
};

export const FieldGenerator = ({ field }: Props) => {
  const [filesOpen, setFilesOpen] = useState(false);
  const [fieldFormik, , { setValue }] = useField(field.name);
  const { setFieldValue } = useFormikContext();

  const onChange = field.onChange
    ? (event) => field.onChange(event, setValue, setFieldValue)
    : (event) => {
        setValue(event?.target?.value ?? event?.target?.checked);
      };
  const postOnChange = field.postOnChange
    ? (val, prevVal) => field.postOnChange(val, prevVal, setValue, setFieldValue)
    : null;
  switch (field.type) {
    case InputType.input:
      return (
        <div className={`${field.useAllRow ? "col-span-full" : ""} ${field.className}`}>
          <TextField
            name={field.name}
            inputMode={field.inputMode}
            label={field.label}
            variant="outlined"
            onChange={onChange}
            placeholder={field.placeholder}
            InputProps={{
              disabled: field.disabled,
              endAdornment: (
                <InputAdornment position="end" className="text-xs pr-0">
                  {field.endAdornment.text && <div>{field.endAdornment.text}</div>}
                  <ColorGradeCompoent endAdornment={field.endAdornment} value={fieldFormik.value} />
                </InputAdornment>
              ),
            }}
          />
        </div>
      );
    case InputType.select:
      if (typeof field?.options?.[0] === "string") {
        return (
          <Select
            name={field.name}
            label={field.label}
            multiple={field.multiple}
            defaultValue={[] as any}
            postOnChange={postOnChange}
            onChange={onChange}
            options={field.options as string[]}
            fullWidth
            containerStyle={{ marginBottom: "16px", gridColumn: field.useAllRow ? "1 / -1" : "" }}
            placeholder={field.placeholder}
            className={field.className}
            disabled={field.disabled}
            endAdornment={
              <InputAdornment position="end" className="text-xs pr-5">
                <ColorGradeCompoent endAdornment={field.endAdornment} value={fieldFormik.value} />
              </InputAdornment>
            }
          />
        );
      } else {
        return (
          <Field name={field.name}>
            {({ field: fieldRender }) => (
              <SelectOptions
                {...fieldRender}
                disabled={field.disabled}
                options={field.options}
                label={"License Plate State"}
                onChange={onChange}
                placeholder={field.placeholder}
              />
            )}
          </Field>
        );
      }
    case InputType.textArea:
      return (
        <div className={`${field.useAllRow ? "col-span-full" : ""} ${field.className}`}>
          <TextAreaField
            name={field.name}
            disabled={field.disabled}
            placeholder={field.placeholder}
            label={field.label}
          />
        </div>
      );
    case InputType.checkbox:
      return (
        <div className={`${field.useAllRow ? "col-span-full" : ""} ${field.className}`}>
          <CheckboxField name={field.name} disabled={field.disabled} label={field.label} postOnChange={postOnChange} />
        </div>
      );
    case InputType.vin:
      return (
        <>
          <div
            className={`flex flex-col items-center p-4 border-[1px] ${
              field.hasVinFile ? "" : "border-[#D50000]"
            } mb-4 rounded-lg`}
          >
            {field.hasVinFile ? (
              <CheckCircle fontSize="medium" style={{ color: "#00C853" }} />
            ) : (
              <Error fontSize="large" style={{ color: "#D50000", flexShrink: 1, marginBottom: "0.5rem" }} />
            )}

            <div className="mt-4 mb-4 text-base">
              {field.hasVinFile ? "VIN picture successfully collected" : "VIN was not collected for this vehicle"}
            </div>

            <Button
              onClick={() => {
                setFilesOpen(true);
              }}
              type="button"
              color={"secondary"}
              variant={"contained"}
            >
              {field.hasVinFile ? "Reupload VIN picture" : "Upload VIN picture"}
            </Button>
            <FilesDialog
              contactId={field.contactId}
              title="Upload VIN Picture"
              open={filesOpen}
              onClose={() => {
                setFilesOpen(false);
              }}
              jobId={field.jobId}
              fileCategory={VIN_FILE_CATEGORY}
            />
          </div>
        </>
      );
    default:
      return <div />;
  }
};
