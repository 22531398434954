import * as React from "react";
import { makeStyles, TextField, Theme } from "@material-ui/core";
import { useField } from "formik";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiFormLabel-root": {
      fontStyle: "italic",
      maxWidth: "80%",
    },
  },
}));

export const TextAreaField: React.FC<{
  disabled?: boolean;
  name: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  postOnChange?: (val: string | number) => void;
}> = ({ disabled = false, name, label, required, postOnChange, placeholder, ...props }) => {
  const classes = useStyles();
  const [field, { error }] = useField(name);
  const { value, onChange, ...rest } = field;
  return (
    <div className="w-full">
      <TextField
        className={classes.root}
        disabled={disabled}
        placeholder={placeholder}
        rows={2}
        fullWidth
        multiline
        variant={"outlined"}
        label={label}
        value={value ? value : ""}
        onChange={(e) => {
          onChange(e);
          const value = e?.target?.value;
          postOnChange && postOnChange(value);
        }}
        {...rest}
        {...props}
      />
      {error && <div style={{ color: "red" }}>{error}</div>}
    </div>
  );
};
