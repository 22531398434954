import * as React from "react";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Contact, Item, Job, PartsStore, Query, Technician } from "../../generated/nest-graphql";
import { format } from "date-fns";
import { equals, path, isEmpty, prop, propOr, uniqBy, reject, anyPass, isNil, pluck } from "ramda";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { ContactListItemDialog } from "../ListItems/ContactListItem";
import { ServiceLocationListItem } from "../ListItems/ServiceLocationListItem";
import { FullScreenDialog } from "../FullScreenDialog";
import { DATE_FORMAT, TIME_FORMAT } from "../../lib/constants";
import { PartsInfoListItem } from "../ListItems/PartsInfoListItem";
import { pipe } from "fp-ts/lib/function";
import { PartsStoreListItem } from "../ListItems/PartsStoreListItem";
import { useQuery } from "@apollo/client";
import { TECHNICIAN_GET_ME } from "../../graphql/queries/technicianGetMe";

export type AppointmentSummaryValues = {
  title: string;
  customerName: string;
  isFixed: boolean;
  appointmentStartDate: Date;
  appointmentEndDate: Date;
  serviceLocation: string;
  jobDescription: string;
  technician: Technician;
  job?: Job;
};

export const getTimeFromDate = (date: Date) => {
  return format(date, TIME_FORMAT);
};

export const AppointmentSummaryDialog: React.FC<{
  open: boolean;
  handleClose: () => void;
  appointmentSummaryValues: AppointmentSummaryValues;
}> = ({ open, handleClose, appointmentSummaryValues }) => {
  const { data: technicianGetMeData } = useQuery<Query>(TECHNICIAN_GET_ME);
  const jobType = path(["job", "type"], appointmentSummaryValues);
  const description = path(["job", "description"], appointmentSummaryValues);
  const make = path(["job", "vehicleInfo", "make"], appointmentSummaryValues);
  const model = path(["job", "vehicleInfo", "model"], appointmentSummaryValues);
  const year = path(["job", "vehicleInfo", "year"], appointmentSummaryValues);
  const job = prop("job", appointmentSummaryValues);
  const contact = path<Contact>(["job", "contact"], appointmentSummaryValues);
  const title = prop("title", appointmentSummaryValues);
  const partsOrderNumber = path<string>(["partsInfo", "partsOrderNumber"], job);
  const partsLocation = path<string>(["partsInfo", "partsLocation"], job);
  const partsNotes = path<string>(["partsInfo", "partsNotes"], job);
  const isFixed = appointmentSummaryValues?.isFixed;
  const partsStores = pipe(
    propOr([], "items", job!) as Item[],
    pluck("partsStore") as () => PartsStore[],
    reject(anyPass([isEmpty, isNil])),
    uniqBy(prop("id"))
  );
  return (
    <div>
      <FullScreenDialog
        title={
          <>
            {title} {isFixed && <span style={{ backgroundColor: "green" }}>FIXED</span>}
          </>
        }
        open={open}
        onClose={handleClose}
      >
        {open && (
          <List>
            {contact && <ContactListItemDialog contact={contact} />}
            <ListItem button divider>
              <ListItemText
                primary={`${format(appointmentSummaryValues.appointmentStartDate, DATE_FORMAT)}`}
                secondary={
                  <>
                    {getTimeFromDate(appointmentSummaryValues.appointmentStartDate)} -{" "}
                    {getTimeFromDate(appointmentSummaryValues.appointmentEndDate)}
                    <Typography className="block" component={"span"} color={"primary"}>
                      Technician: {path(["technician", "firstName"], appointmentSummaryValues)}{" "}
                      {path(["technician", "lastName"], appointmentSummaryValues)}
                    </Typography>
                  </>
                }
              />
            </ListItem>
            {prop("serviceLocation", appointmentSummaryValues) && (
              <ServiceLocationListItem
                address={appointmentSummaryValues.serviceLocation}
                notes={prop("serviceLocationNotes", job!)}
              />
            )}
            {prop("job", appointmentSummaryValues) && (
              <>
                <ListItem button divider={true}>
                  <ListItemText primary={`${jobType} - ${make} ${model} ${year}`} />
                </ListItem>
                {partsOrderNumber && (
                  <PartsInfoListItem
                    partsLocation={partsLocation}
                    partsNotes={partsNotes}
                    partsOrderNumber={partsOrderNumber}
                  />
                )}
                {description && (
                  <ListItem divider={true}>
                    <ListItemText primary={"Description"} secondary={description as React.ReactNode} />
                  </ListItem>
                )}
                {!isEmpty(partsStores) && (
                  <>
                    <ListItem divider={false}>
                      <ListItemText primary={<Typography style={{ fontWeight: "bold" }}>Parts Store(s)</Typography>} />
                    </ListItem>
                    {partsStores.map((partsStore, idx) => {
                      return (
                        <PartsStoreListItem
                          key={idx}
                          partsStore={partsStore}
                          isHomePartsStore={equals(
                            (partsStore as any)?.id,
                            technicianGetMeData?.technicianGetMe?.homePartsStore?.id
                          )}
                        />
                      );
                    })}
                  </>
                )}
                <ListItem className="justify-center">
                  <Link to={`/jobs/${job?.id}`}>
                    <Button color={"primary"} variant={"contained"} fullWidth={true}>
                      View Job
                    </Button>
                  </Link>
                </ListItem>
              </>
            )}
          </List>
        )}
      </FullScreenDialog>
    </div>
  );
};
