import React, { useState, useEffect } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Form, Formik, yupToFormErrors } from "formik";
import { Paper } from "@material-ui/core";
import { SubmitButton } from "../Buttons/SubmitButton";
import * as Yup from "yup";
import { PartConditionListItem } from "../ListItems/PartConditionListItem";
import Box from "@material-ui/core/Box";
import { FullScreenDialog } from "../FullScreenDialog";
import { useInspectionReport } from "../../contexts/inspection-context";
import { mergePreJobChecklistFunc } from "../Jobs/JobStatusSection";
import { PartConditionOptions } from "../Forms/fields/PartConditionOptions";
import { TextAreaField } from "../Forms/fields/TextAreaField";
import { PostJobCheckList } from "./types";
// import { TireTreadDropdownListItem } from "../ListItems/TireTreadDropdownListItem";

const needInfoConditions = [
  "Yellow (Has wear, not in need of immediate repair)",
  "Red (Needs immediate repair)",
  "Warning lights on",
];

const startOfToday = new Date();
startOfToday.setHours(0, 0, 0, 0);
const endOfToday = new Date();
endOfToday.setHours(23, 59, 59, 59);

// const tireTreadDepthSchema = Yup.object().shape(
//   {
//     depth: Yup.string().when("unavailable", {
//       is: (unavailable) => unavailable === false || unavailable === null,
//       then: Yup.string().required(),
//       otherwise: Yup.string().nullable(),
//     }),
//     unavailable: Yup.boolean().when("depth", {
//       is: (depth) => depth === null || depth === undefined || depth?.length === 0,
//       then: Yup.boolean().oneOf([true]).required(),
//       otherwise: Yup.boolean().nullable(),
//     }),
//   },
//   [["depth", "unavailable"]]
// );

// const tireTreadSchema = Yup.object().shape({
//   frontLeft: tireTreadDepthSchema,
//   frontRight: tireTreadDepthSchema,
//   rearLeft: tireTreadDepthSchema,
//   rearRight: tireTreadDepthSchema,
// });

const schema = Yup.object().shape({
  wheelStuds: Yup.string().nullable().required("required"),
  lugnuts: Yup.string().nullable().required("required"),
  dashboardLights: Yup.string().nullable().required("required"),
  testDriveExpirience: Yup.string().nullable().required("required"),
  otherConcerns: Yup.string().nullable(),
  // tireTread: tireTreadSchema,
});

export const PostJobCheckListFormCore: React.FC<{
  initialValues: PostJobCheckList;
  onSubmit: (values: PostJobCheckList) => void;
  open: boolean;
  onClose: (param) => void;
}> = ({ initialValues, onSubmit, open, onClose }) => {
  return (
    <Formik<PostJobCheckList>
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnMount
      validateOnBlur
      enableReinitialize
    >
      {({ values, isValid, isSubmitting, errors }) => {
        return (
          <FullScreenDialog title={"Inspection"} open={open} onClose={() => onClose(values)} headerPosition={"fixed"}>
            <Paper className="pt-20">
              <Form>
                <Box className={"px-4"}>
                  <List component="nav">
                    <PartConditionListItem name={"wheelStuds"} label={"Wheel Studs Condition"} />
                    <PartConditionListItem name={"lugnuts"} label={"Lug Nuts Torqued"} />
                    <PartConditionListItem name={"dashboardLights"} label={"Dashboard Lights"} />
                    <PartConditionListItem
                      name={"testDriveExpirience"}
                      label={"Were you able to test drive the vehicle?"}
                    />
                    {/* <TireTreadDropdownListItem name={"tireTread"} label={"Tire Tread"} /> */}
                    <div className="flex flex-col p-4 border-[1px] rounded-lg mb-4">
                      <h2 className="text-lg mb-4">Any other safety/ warranty concerns?</h2>
                      <div className="flex flex-row">
                        <TextAreaField label={""} name={"otherConcerns"} placeholder="" />
                      </div>
                    </div>
                    <SubmitButton isSubmitting={isSubmitting} isValid={isValid} label={"Submit"} />
                  </List>
                </Box>
              </Form>
            </Paper>
          </FullScreenDialog>
        );
      }}
    </Formik>
  );
};
