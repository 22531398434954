import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { useField } from "formik";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { path } from "ramda";
import { useTheme } from "@material-ui/core";

export const CheckboxField: React.FC<{
  name: string;
  label?: string;
  required?: boolean;
  styling?: any;
  postOnChange?: any;
  labelPlacement?: "end" | "start" | "top" | "bottom";
  classNames?: string;
  labelClasses?: any;
  disabled?: boolean;
}> = ({
  name,
  label,
  required,
  styling,
  postOnChange,
  labelPlacement = "end",
  classNames = "",
  labelClasses,
  ...props
}) => {
  const [field, meta, helper] = useField(name);
  const theme = useTheme();
  return (
    <div className={classNames}>
      <FormControlLabel
        control={
          <Checkbox
            {...field}
            {...props}
            checked={field.value}
            color="primary"
            inputProps={{ "aria-label": "secondary checkbox" }}
            style={{ color: !!meta.error && theme.palette.primary.main }}
            onChange={(event) => {
              // @ts-ignore
              const value = path<string>(["target", "checked"], event);
              const previousVal = field.value;
              helper.setValue(value);
              setTimeout(() => {
                postOnChange && postOnChange(value, previousVal);
              });
            }}
          />
        }
        label={label}
        labelPlacement={labelPlacement}
        classes={labelClasses}
      />
    </div>
  );
};
