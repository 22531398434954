import * as React from "react";
import { defaultTo, find, map, pipe, pluck, prop, propEq, propOr, props, sum } from "ramda";
import { Form, Formik } from "formik";
import {
  Contact,
  Item,
  Mutation,
  MutationDeleteInvoiceArgs,
  ServiceFollowUp,
  Technician,
} from "../../generated/nest-graphql";
import { TextAreaField } from "./fields/TextAreaField";
import Container from "@material-ui/core/Container";
import { ListItemWithSubList } from "../ListItems/ListItemWithSubList";
import { Link, useHistory } from "react-router-dom";
import List from "@material-ui/core/List";
import { ContactListItem } from "../ListItems/ContactListItem";
import { ItemsField } from "./fields/ItemsField";
import Paper from "@material-ui/core/Paper";
import { toNumber } from "../../lib/functions";
import { ReceiptView } from "../Invoices/ReceiptView";
import { BalanceDueView } from "../Invoices/BalanceDueView";
import { VehicleInfoValues } from "../../types/VehicleInfoValues";
import { BottomSubmitButtonRow } from "../Buttons/BottomSubmitButtonRow";
import { DeleteButton } from "../Invoices/DeleteButton";
import { useMutation } from "@apollo/client";
import { DELETE_INVOICE } from "../../graphql/mutations/deleteInvoice";
import { useMarkets } from "../../hooks/useMarkets";
import { flow } from "fp-ts/lib/function";
import currency from "currency.js";
import { ServicesSection } from "../services/ServicesSection";
import { CREATED_INVOICE, SERVICE_CATALOGUE_USED_SERVICES } from "../../lib/constants";
import { PreJobCheckListFormValues } from "../Jobs/PreJobCheckListDialog";
import { EJIDiscountFormValue, EJIPriceInfoFormValue, EJIServiceFormValue, PromoCodeFormValue } from "./JobDetailsForm";
import { PreJobCheckListV2FormValues } from "../PreJobChecklistV2/PreJobCheckListV2Dialog";

export type UpdateInvoiceDetailsFormValues = {
  contact: Contact;
  serviceLocation: string;
  status: string;
  estimate?: string;
  taxable?: boolean;
  privateNotes?: string;
  market: string;
  technician: Technician;
  items: Item[];
  services: EJIServiceFormValue[];
  customerMessage?: string;
  issuedDate: Date;
  jobNumber?: string;
  dueDate: Date;
  jobId?: string;
  discounts?: EJIDiscountFormValue[];
  promoCodes?: PromoCodeFormValue[];
  serviceCatalogueUsed?: string;
  priceInfo?: EJIPriceInfoFormValue;
  preJobCheckList?: PreJobCheckListFormValues;
  preJobCheckListV2?: PreJobCheckListV2FormValues;
  serviceFollowUp?: ServiceFollowUp;
  proceedingWithRepairs?: boolean;
} & VehicleInfoValues;

type UpdateInvoiceDetailsFormProps = {
  pinSaveToBottom: boolean;
  itemsEditable: boolean;
  initialValues: UpdateInvoiceDetailsFormValues;
  onSubmit: any;
  amountPaid: string;
  balanceDue: string;
  invoiceId: string;
  jobId: string;
};

export const UpdateInvoiceDetailsForm: React.FC<UpdateInvoiceDetailsFormProps> = ({
  initialValues,
  onSubmit,
  amountPaid,
  balanceDue,
  itemsEditable,
  pinSaveToBottom,
  invoiceId,
  jobId,
}) => {
  const { push } = useHistory();
  const [deleteInvoice] = useMutation<Mutation, MutationDeleteInvoiceArgs>(DELETE_INVOICE);
  const markets = useMarkets();

  return (
    <Formik<UpdateInvoiceDetailsFormValues> initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true}>
      {({ values, setFieldValue, isSubmitting, isValid, submitForm, dirty, setValues }) => {
        const taxable: boolean = propOr(false, "taxable", values);
        const subtotal = pipe(pluck("amount"), map(toNumber), sum, currency)(values.items);
        const partsCost = pipe(pluck("partsCost"), map(toNumber), sum, currency)(values.items);
        const laborCost = subtotal.subtract(partsCost);
        const [partsTaxRate, laborTaxRate]: any = flow(
          defaultTo([]),
          find(propEq("name", prop("market", initialValues))),
          props(["partsTaxRate", "laborTaxRate"])
        )(markets);
        const partsTax = partsCost.multiply(partsTaxRate / 100);
        const laborTax = laborCost.multiply(laborTaxRate / 100);
        const taxAmount = taxable ? partsTax.add(laborTax) : 0;
        const total = subtotal.add(taxAmount);

        return (
          <Form
            // padding bottom to offset save button
            className="pb-4"
          >
            <Paper>
              <List>
                <ContactListItem contact={values.contact} />
                <Link to={`/jobs/${values.jobId}`}>
                  <ListItemWithSubList
                    divider={true}
                    title={`View Job: ${values.jobNumber}`}
                    secondaries={[]}
                    button={true}
                  />
                </Link>
              </List>
              <Container className={"py-4 mb-4"}>
                <div className="grid gap-4">
                  {values.serviceCatalogueUsed === SERVICE_CATALOGUE_USED_SERVICES ? (
                    <ServicesSection
                      services={values.services}
                      values={values}
                      setValues={setValues}
                      id={invoiceId}
                      stage={CREATED_INVOICE}
                    />
                  ) : (
                    <>
                      <ItemsField
                        parentSubmit={submitForm}
                        name={"items"}
                        setFieldValue={setFieldValue}
                        editable={itemsEditable}
                      />
                      <ReceiptView
                        subtotal={subtotal.toString()}
                        total={total.toString()}
                        tax={taxAmount.toString()}
                        parts={partsCost.toString()}
                        labor={laborCost.toString()}
                      />
                      <BalanceDueView amountPaid={amountPaid} balanceDue={balanceDue} />
                    </>
                  )}
                  <TextAreaField name={"customerMessage"} label={"Customer Message"} />
                  <TextAreaField name={"privateNotes"} label={"Private Notes"} />
                  {values.status === "Draft" && (
                    <DeleteButton
                      onDelete={async () => {
                        await deleteInvoice({
                          variables: {
                            id: invoiceId,
                          },
                        });
                        push(`/jobs/${jobId}`);
                      }}
                    />
                  )}
                </div>
              </Container>
            </Paper>
            <BottomSubmitButtonRow
              isSubmitting={isSubmitting}
              isValid={isValid && dirty}
              pinToBottom={pinSaveToBottom}
              label={"Update Invoice"}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
