import React from "react";
import { PreJobCheckListV2FormValues } from "./PreJobCheckListV2Dialog";
import { Button, Paper } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { FullScreenDialog } from "../FullScreenDialog";
import { Form, Formik } from "formik";
import { VIN_FILE_CATEGORY } from "./constants";
import { SubmitButton } from "../Buttons/SubmitButton";
import { useQuery } from "@apollo/client";
import { GET_JOB_FOR_INSPECTION } from "../../graphql/queries/getJob";
import { Query, QueryGetFilesArgs, QueryGetJobArgs, QueryGetVehicleArgs } from "../../generated/nest-graphql";
import { getValidationV2Schema } from "./validation";
import { GET_FILES } from "../../graphql/queries/getFiles";
import { CorePreJobCheckList, ViocPreJobCheckList } from "../Forms/utils/forms";
import { FormGenerator } from "../Forms/utils/FormGenerator";
import { GET_VEHICLE } from "../../graphql/queries/getVehicles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentAlt } from "@fortawesome/free-solid-svg-icons";
import { nestBackendClient } from "../../lib/nestBackendClient";
import { showErrorAlert, showSuccessAlert } from "../../actions";
import { useDispatch } from "../../contexts/snackbar-context";
import { AreYouSureDialog } from "../Items/AreYouSureDialog";
import { useToggle } from "../../hooks/useToggle";

const toOptionalFixed = (value: number, digits: number) => `${Number.parseFloat(value.toFixed(digits))}`;

export const PreJobCheckListV2Form: React.FC<{
  initialValues: PreJobCheckListV2FormValues;
  onSubmit: (values: PreJobCheckListV2FormValues) => void | Promise<void>;
  open: boolean;
  onClose: (values: PreJobCheckListV2FormValues) => void;
  jobId: string;
}> = ({ initialValues, onSubmit, open, onClose, jobId }) => {
  const dispatch = useDispatch();
  const [areYouSureDialogOpen, , toggleAreYouSureDialog] = useToggle();
  const { data, refetch } = useQuery<Query, QueryGetJobArgs>(GET_JOB_FOR_INSPECTION, {
    fetchPolicy: "cache-first",
    variables: {
      id: jobId,
    },
    skip: !jobId,
  });
  const {
    data: { getVehicle: vehicle },
  } = useQuery<Query, QueryGetVehicleArgs>(GET_VEHICLE, {
    fetchPolicy: "cache-first",
    variables: {
      id: data?.getJob.vehicleId,
    },
    skip: !data?.getJob.vehicleId,
  });

  let frontRotorsDiscardThicknessInches;

  if (data?.getJob?.vehicleInfo?.frontRotorsDiscardThicknessInches) {
    frontRotorsDiscardThicknessInches = data.getJob.vehicleInfo.frontRotorsDiscardThicknessInches;
  } else {
    frontRotorsDiscardThicknessInches = vehicle?.vehicleTechnicalInfo?.frontRotorsDiscardThicknessInches;
  }

  const rearRotorsDiscardThicknessInches = vehicle?.vehicleTechnicalInfo.rearRotorsDiscardThicknessInches;
  const sendDIgitalInspection = async () => {
    try {
      const response = await nestBackendClient.post("job-appointments/send-digital-inspection", {
        jobId,
        contact: data.getJob.contact.id,
        phoneNumber: data.getJob.contact.phoneNumber,
      });
      refetch();
      showSuccessAlert(dispatch, "Inspection sended successfully");
    } catch (error) {
      showErrorAlert(dispatch, error.message);
    }
  };

  const { data: getFilesData } = useQuery<Query, QueryGetFilesArgs>(GET_FILES, {
    variables: {
      contactId: data?.getJob?.contact?.id,
    },
  });

  const hasVinFile = getFilesData?.getFiles?.some(
    (file) => file?.category === VIN_FILE_CATEGORY && file?.jobId === jobId
  );
  const hasVinInfo = !!data?.getJob?.vehicleInfo?.vin;

  const hasCCA = !!(vehicle?.CCA ?? data.getJob.vehicleInfo?.CCA);

  const hasLp = !!data?.getJob?.vehicleInfo?.licensePlate;

  const isVioc = data.getJob.type === "Vioc Inspection";
  return (
    <>
      <Formik<PreJobCheckListV2FormValues>
        validationSchema={getValidationV2Schema(isVioc, vehicle)}
        initialValues={initialValues}
        onSubmit={async (values) => {
          await onSubmit(values);
          if (data.getJob.status !== "Closed" && !data.getJob.inspectionReportSentAt) {
            toggleAreYouSureDialog();
          }
        }}
        validateOnMount
        validateOnBlur
        validateOnChange
        enableReinitialize
      >
        {({ values, isValid, isSubmitting }) => {
          const baseInjectProperties = [
            {
              route: "frontRotors.children.thicknessInches.endAdornment.secondColorGradeParam",
              value: frontRotorsDiscardThicknessInches?.toString(),
            },
            {
              route: "rearRotors.children.thicknessInches.endAdornment.secondColorGradeParam",
              value: (rearRotorsDiscardThicknessInches ?? frontRotorsDiscardThicknessInches)?.toString(),
            },
            // {
            //   route: "batteryHealth.children.coldCrankingAmps.disabled",
            //   value: values.withoutCCA,
            // },
            // {
            //   route: "batteryHealth.children.coldCrankingAmps.endAdornment.secondColorGradeParam",
            //   value: vehicle.CCA,
            // },
            {
              route: "batteryHealth.children.batteryHealth.disabled",
              value: values.withoutBatteryHealth,
            },
          ];
          const form = isVioc
            ? ViocPreJobCheckList(
                values,
                [
                  ...baseInjectProperties,
                  {
                    route: "frontRotors.description",
                    value: `Dicard thickness: ${
                      frontRotorsDiscardThicknessInches
                        ? `${toOptionalFixed(frontRotorsDiscardThicknessInches, 8)} inches`
                        : "Not provided"
                    }`,
                  },
                  {
                    route: "uploadVin.hasVinFile",
                    value: hasVinFile,
                  },
                  {
                    route: "uploadVin.contactId",
                    value: data?.getJob?.contact?.id,
                  },
                  {
                    route: "uploadVin.jobId",
                    value: data?.getJob?.id,
                  },
                ],
                { hasVinInfo, hasLp, hasCCA }
              )
            : CorePreJobCheckList(values, baseInjectProperties, { hasVinInfo, hasLp, hasCCA });
          return (
            <FullScreenDialog
              title={"Inspection"}
              open={open}
              onClose={() => {
                onClose(values);
              }}
              headerPosition={"fixed"}
            >
              <Paper className="pt-20">
                <div className="px-4 pb-4">
                  <h2>Measurements should reflect the condition of the vehicle before NuBrakes completed repairs.</h2>
                </div>
                <Form>
                  <Box className="px-4 h-full">
                    <FormGenerator form={form} />
                    <SubmitButton isSubmitting={isSubmitting} isValid={isValid} label={"Submit"} />
                    {true && (
                      <div className="mt-4">
                        <Button
                          type="button"
                          className="uppercase w-full"
                          variant="contained"
                          color="primary"
                          startIcon={<FontAwesomeIcon icon={faCommentAlt as any} />}
                          onClick={sendDIgitalInspection}
                        >
                          Send Inspection Report Link
                        </Button>
                      </div>
                    )}
                  </Box>
                </Form>
              </Paper>
            </FullScreenDialog>
          );
        }}
      </Formik>
      <AreYouSureDialog
        onNo={toggleAreYouSureDialog}
        onYes={async () => {
          await sendDIgitalInspection();
          toggleAreYouSureDialog();
        }}
        yesButtonType="button"
        title={`Send customer a Digital Inspection Report?`}
        subTitle="Customer will receive a link via text "
        open={areYouSureDialogOpen}
        onClose={toggleAreYouSureDialog}
      />
    </>
  );
};
