import { lensPath, set } from "ramda";
import { odometer, usesRearShoesAndDrums, updloadVin, FieldObject } from "./fields";
import {
  brakeFluid,
  frontPads,
  frontRotors,
  inspectedServices,
  inspectionPrivateNotes,
  rearDrums,
  rearPads,
  rearRotors,
  rearShoes,
  licensePlate,
  SectionObject,
  frontPadsCore,
  frontRotorsCore,
  brakeFluidCore,
  rearPadsCore,
  inspectedServicesCore,
  rearRotorsCore,
  batteryHealthSection,
} from "./sections";

const parseForm = (item: FormItemsRecord): FormItems =>
  Object.entries(item).map(([key, value]) => {
    if ("children" in value && typeof value.children === "object") {
      return {
        ...value,
        children: parseForm(value.children as FormItemsRecord),
      };
    } else {
      return value;
    }
  });

type InjectProperty = {
  route: string;
  value: any;
};

export type FormItems = (SectionObject | FieldObject)[];
type FormItemsRecord = Record<string, SectionObject | FieldObject>;

type ViocProps = {
  hasVinInfo?: boolean;
  hasLp?: boolean;
  hasCCA?: boolean;
};

type CoreProps = {
  hasVinInfo?: boolean;
  hasLp?: boolean;
  hasCCA?: boolean;
};

type formFunction<T> = (value: Record<string, any>, injectProperties?: InjectProperty[], otherProps?: T) => FormItems;

export const ViocPreJobCheckList: formFunction<ViocProps> = (
  values,
  injectProperties,
  { hasVinInfo, hasLp, hasCCA }
) => {
  let dependsCheckbox = values.usesRearShoesAndDrums
    ? {
        rearShoes: rearShoes(),
        rearDrums: rearDrums(),
      }
    : {
        rearPads: rearPads(),
        rearRotors: rearRotors(),
      };

  let result = {
    odometer: odometer({ required: true, useAllRow: true, className: "mb-3" }),
    brakeFluid: brakeFluid(),
    frontPads: frontPads(),
    frontRotors: frontRotors(),
    usesRearShoesAndDrums: usesRearShoesAndDrums({ useAllRow: true, className: "mb-3" }),
    ...dependsCheckbox,
    // ...(hasCCA ? { batteryHealth: batteryHealthSection() } : null),
    batteryHealth: batteryHealthSection(),
    inspectedServices: inspectedServices(),
    inspectionPrivateNotes: inspectionPrivateNotes(),
    ...(!hasVinInfo ? { uploadVin: updloadVin() } : null),
    ...(hasVinInfo || hasLp ? { licensePlate: licensePlate() } : null),
  };
  if (injectProperties?.length) {
    injectProperties.forEach(({ route, value }) => {
      result = set(lensPath(route.split(".")), value, result);
    });
  }
  return parseForm(result);
};

export const CorePreJobCheckList: formFunction<CoreProps> = (
  values,
  injectProperties,
  { hasLp, hasVinInfo, hasCCA }
) => {
  let dependsCheckbox = values.usesRearShoesAndDrums
    ? {
        rearShoes: rearShoes(),
        rearDrums: rearDrums(),
      }
    : {
        rearPads: rearPadsCore(),
        rearRotors: rearRotorsCore(),
      };

  let result = {
    odometer: odometer({ required: true, useAllRow: true, className: "mb-3" }),
    brakeFluid: brakeFluidCore(),
    frontPads: frontPadsCore(),
    frontRotors: frontRotorsCore(),
    usesRearShoesAndDrums: usesRearShoesAndDrums({ useAllRow: true, className: "mb-3" }),
    ...dependsCheckbox,
    // ...(hasCCA ? { batteryHealth: batteryHealthSection() } : null),
    batteryHealth: batteryHealthSection(),
    inspectedServices: inspectedServicesCore(),
    inspectionPrivateNotes: inspectionPrivateNotes(),
    ...(hasVinInfo || hasLp ? { licensePlate: licensePlate({ required: false }) } : null),
  };
  if (injectProperties?.length) {
    injectProperties.forEach(({ route, value }) => {
      result = set(lensPath(route.split(".")), value, result);
    });
  }
  return parseForm(result);
};
