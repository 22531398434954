import * as React from "react";
import { EJIServiceFormValue } from "./JobDetailsForm";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import { AddPartSection } from "./AddPartSection";
import { StyledHR } from "./UpdateServiceDetailsFormSection/StyledHR";
import { FeeItemSection } from "./UpdateServiceDetailsFormSection/FeeItemSection";
import { PartItemSection } from "./UpdateServiceDetailsFormSection/PartItemSection";
import { isEjiServiceFormValueValid } from "./UpdateServiceDetailsFormSection/isEjiServiceFormValueValid";
import { isEmpty } from "ramda";
import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { PossibleEjiService, Query } from "../../generated/nest-graphql";
import { GET_PARTS_STORES } from "../../graphql/queries/getPartsStores";
import { TECHNICIAN_GET_ME } from "../../graphql/queries/technicianGetMe";
import { CircularProgress } from "@material-ui/core";
import { useDispatch } from "../../contexts/snackbar-context";
import { showErrorAlert } from "../../actions";

export const UpdateServiceDetailsFormSection: React.FC<{
  editedService: EJIServiceFormValue;
  setEditedServiceAndRecalculatePrice: any;
  servicesList: PossibleEjiService[];
}> = ({ editedService, setEditedServiceAndRecalculatePrice, servicesList }) => {
  const dispatch = useDispatch();
  const {
    data: getPartsStoreData,
    loading: loadingGetPartsStore,
    error: errorGetPartsStore,
  } = useQuery<Query>(GET_PARTS_STORES, {
    variables: {
      filter: {
        deactivated: { $ne: true },
      },
    },
  });
  const { data: technicianGetMeData, loading: loadingTechnicianGetMe } = useQuery<Query>(TECHNICIAN_GET_ME);
  let filteredPartsStores = useMemo(() => {
    if (!technicianGetMeData || !getPartsStoreData) {
      return [];
    }
    return getPartsStoreData.getPartsStores.filter(
      (partsStore) => partsStore.market?.name === technicianGetMeData.technicianGetMe?.market
    );
  }, [getPartsStoreData, technicianGetMeData]);
  const filteredParts = editedService?.items.filter((product) => product?.category === "Part");
  const customerPriceText =
    !isEjiServiceFormValueValid(editedService, servicesList) && !isEmpty(editedService)
      ? "--"
      : String(editedService.customerPrice ?? 0.0);

  React.useEffect(() => {
    const error = errorGetPartsStore;
    if (error) {
      showErrorAlert(dispatch, error.message);
    }
  }, [errorGetPartsStore, dispatch]);
  if (loadingGetPartsStore || loadingTechnicianGetMe) {
    return (
      <div className={"flex flex-row justify-center"}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <div>
      <div className="text-xl font-bold mb-1">Customer Price ${customerPriceText}</div>
      <div className="mb-8">
        <InputLabel shrink className="mb-1">
          Service Name
        </InputLabel>
        <Typography className="pb-2">{editedService?.name}</Typography>
        <StyledHR />
      </div>
      {editedService?.items?.map((item, itemIdx) => (
        <div className={"grid grid-cols-1 gap-4"} key={itemIdx}>
          {item?.category === "Part" && (
            <PartItemSection
              editedService={editedService}
              setEditedServiceAndRecalculatePrice={setEditedServiceAndRecalculatePrice}
              item={item}
              itemIdx={itemIdx}
              filteredParts={filteredParts}
              filteredPartsStores={filteredPartsStores}
            ></PartItemSection>
          )}
          {item?.category === "Fee" && (
            <FeeItemSection
              editedService={editedService}
              setEditedServiceAndRecalculatePrice={setEditedServiceAndRecalculatePrice}
              itemIdx={itemIdx}
            ></FeeItemSection>
          )}
        </div>
      ))}
      <div className={"pb-1"}>
        <AddPartSection
          editedService={editedService}
          setEditedServiceAndRecalculatePrice={setEditedServiceAndRecalculatePrice}
        ></AddPartSection>
      </div>
    </div>
  );
};
