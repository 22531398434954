import { indexBy } from "ramda";
import { ChecklistInspectedServiceOption } from "./types";

export const POSSIBLE_INSPECTED_SERVICES_LIST: {
  name: ChecklistInspectedServiceOption;
  label: string;
}[] = [
  {
    name: "frontLeftCaliper",
    label: "Front Left Caliper",
  },
  {
    name: "frontRightCaliper",
    label: "Front Right Caliper",
  },
  {
    name: "rearLeftCaliper",
    label: "Rear Left Caliper",
  },
  {
    name: "rearRightCaliper",
    label: "Rear Right Caliper",
  },
  {
    name: "frontLeftHubAssemblyBearing",
    label: "Front Left Hub Assembly/Bearing",
  },
  {
    name: "frontRightHubAssemblyBearing",
    label: "Front Right Hub Assembly/Bearing",
  },
  {
    name: "rearLeftHubAssemblyBearing",
    label: "Rear Left Hub Assembly/Bearing",
  },
  {
    name: "rearRightHubAssemblyBearing",
    label: "Rear Right Hub Assembly/Bearing",
  },
];

export const POSSIBLE_INSPECTED_SERVICES_INDEXED = indexBy((service) => service.name, POSSIBLE_INSPECTED_SERVICES_LIST);

export const GOOD_CONDITION_OPTION = "Good condition";

export const PADS_CONDITION_OPTIONS = [
  GOOD_CONDITION_OPTION,
  "Glazing",
  "Uneven Wear",
  "Rust",
  "Cracks",
  "Discoloration",
  "Grinding",
];

export const ROTORS_CONDITION_OPTIONS = [
  GOOD_CONDITION_OPTION,
  "Grooving",
  "Uneven Wear/Rust lip",
  "Rust",
  "Cracks",
  "Heat spots",
  "Grinding",
  "Warped",
  "Glazing",
];

export const SHOES_CONDITIONS_OPTIONS = [
  GOOD_CONDITION_OPTION,
  "Squeaking/rubbing/scraping noises during road test",
  "Extreme rust",
  "Out-of-round drum",
];

export const DRUMS_CONDITIONS_OPTIONS = [
  GOOD_CONDITION_OPTION,
  "Squeaking/rubbing/scraping noises during road test",
  "Extreme rust",
  "Out-of-round drum",
];

export const INSPECTION_COLOR_GRADES = ["Red", "Yellow", "Green"];

export const US_STATES = [
  { value: "Alabama", label: "AL" },
  { value: "Alaska", label: "AK" },
  { value: "Arizona", label: "AZ" },
  { value: "Arkansas", label: "AR" },
  { value: "California", label: "CA" },
  { value: "Colorado", label: "CO" },
  { value: "Connecticut", label: "CT" },
  { value: "Delaware", label: "DE" },
  { value: "Florida", label: "FL" },
  { value: "Georgia", label: "GA" },
  { value: "Hawaii", label: "HI" },
  { value: "Idaho", label: "ID" },
  { value: "Illinois", label: "IL" },
  { value: "Indiana", label: "IN" },
  { value: "Iowa", label: "IA" },
  { value: "Kansas", label: "KS" },
  { value: "Kentucky", label: "KY" },
  { value: "Louisiana", label: "LA" },
  { value: "Maine", label: "ME" },
  { value: "Maryland", label: "MD" },
  { value: "Massachusetts", label: "MA" },
  { value: "Michigan", label: "MI" },
  { value: "Minnesota", label: "MN" },
  { value: "Mississippi", label: "MS" },
  { value: "Missouri", label: "MO" },
  { value: "Montana", label: "MT" },
  { value: "Nebraska", label: "NE" },
  { value: "Nevada", label: "NV" },
  { value: "New Hampshire", label: "NH" },
  { value: "New Jersey", label: "NJ" },
  { value: "New Mexico", label: "NM" },
  { value: "New York", label: "NY" },
  { value: "North Carolina", label: "NC" },
  { value: "North Dakota", label: "ND" },
  { value: "Ohio", label: "OH" },
  { value: "Oklahoma", label: "OK" },
  { value: "Oregon", label: "OR" },
  { value: "Pennsylvania", label: "PA" },
  { value: "Rhode Island", label: "RI" },
  { value: "South Carolina", label: "SC" },
  { value: "South Dakota", label: "SD" },
  { value: "Tennessee", label: "TN" },
  { value: "Texas", label: "TX" },
  { value: "Utah", label: "UT" },
  { value: "Vermont", label: "VT" },
  { value: "Virginia", label: "VA" },
  { value: "Washington", label: "WA" },
  { value: "West Virginia", label: "WV" },
  { value: "Wisconsin", label: "WI" },
  { value: "Wyoming", label: "WY" },
];

export const VIN_FILE_CATEGORY = "VIN";
