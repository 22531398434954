import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { FormControl, FormControlLabel, FormGroup, FormHelperText } from "@material-ui/core";

export type CheckboxesFieldProps = {
  name: string;
  options: CheckboxesFieldOption[];
  onChange: any;
  values?: string[];
  required?: boolean;
  error?: boolean;
  errorText?: string;
};

export type CheckboxesFieldOption = {
  label: string;
  value: string;
};

export const CheckboxesField = ({
  name,
  options,
  onChange,
  values = [],
  required = false,
  error = false,
  errorText = "One must be selected",
}: CheckboxesFieldProps) => {
  return (
    <FormControl required={required} error={error} component="fieldset">
      {error && <FormHelperText>{errorText}</FormHelperText>}
      <FormGroup>
        {options.map((option, index) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  name={name}
                  color={"primary"}
                  onChange={onChange}
                  required={required}
                  checked={values?.includes(option.value)}
                  {...option}
                />
              }
              label={option.label}
              key={index}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
};
