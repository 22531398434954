import * as React from "react";
import { Button, Link, ListItem } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { ReactNode } from "react";
import { pipe } from "ramda";

const generateSecondary = (text: string | React.ReactNode | null) => {
  if (typeof text === "string") {
    const URL_REGEX =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

    const renderText = (txt) =>
      txt.split(" ").map((part) => (URL_REGEX.test(part) ? <Link href={part}>{part} </Link> : part + " "));
    return renderText(text);
  }
  return text;
};

export const ButtonListItem: React.FC<{
  secondary: ReactNode;
  primary: ReactNode;
  href: string;
  buttonText: string;
}> = ({ primary, href, buttonText, secondary }) => {
  return (
    <ListItem divider style={{ paddingRight: 90 }}>
      <ListItemText
        primary={primary}
        secondary={generateSecondary(secondary as string)}
        primaryTypographyProps={{
          style: {
            overflowWrap: "break-word",
          },
        }}
      />
      <ListItemSecondaryAction>
        <a href={href}>
          <Button color={"primary"} variant={"outlined"}>
            {buttonText}
          </Button>
        </a>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
