import {
  anyPass,
  either,
  isEmpty,
  isNil,
  keys,
  path,
  pathOr,
  pluck,
  prop,
  propOr,
  propEq,
  reject,
  uniqBy,
  last,
  mergeDeepWith,
} from "ramda";
import React, { useEffect } from "react";
import {
  Contact,
  Invoice,
  Mutation,
  MutationUpdateInvoiceArgs,
  MutationUpdateJobArgs,
  PartsOrderingIssuesSurvey,
  PartsStore,
  Query,
  QueryGetInvoiceArgs,
} from "../../generated/nest-graphql";
import { GET_INVOICE } from "../../graphql/queries/getInvoice";
import { useMutation, useQuery } from "@apollo/client";
import Paper from "@material-ui/core/Paper";
import { Layout } from "../../components/Layout";
import { UPDATE_INVOICE } from "../../graphql/mutations/updateInvoice";
import { cleanObject, objectDiff } from "../../lib/functions";
import { InvoiceDetailsAppBar } from "../../components/Invoices/InvoiceDetailsAppBar";
import {
  UpdateInvoiceDetailsForm,
  UpdateInvoiceDetailsFormValues,
} from "../../components/Forms/UpdateInvoiceDetailsForm";
import { updateInvoiceDetailsSpec } from "../../components/Invoices/invoiceDetailsSpec";
import { useEntityId } from "../../hooks/useEntityId";
import { showErrorAlert, showSuccessAlert } from "../../actions";
import { useDispatch } from "../../contexts/snackbar-context";
import { useToggle } from "../../hooks/useToggle";
import { pipe } from "fp-ts/lib/function";
import { TECHNICIAN_GET_ME } from "../../graphql/queries/technicianGetMe";
import { preJobCheckListInitialValues, preJobCheckListV2InitialValues } from "../../components/Jobs/jobDetailsSpec";
import { UPDATE_JOB } from "../../graphql/mutations/updateJob";
import { useHistory } from "react-router-dom";
import { DEFAULT_SERVICE_CATALOGUE_USED, SERVICE_CATALOGUE_USED_SERVICES } from "../../lib/constants";
import currency from "currency.js";
import {
  ejiDiscountsToFormValuesSpec,
  ejiPriceInfoToFormValue,
  ejiServicesToFormValuesSpec,
} from "../../specs/ejiServicesSpec";
import { isToday } from "date-fns";
import {
  mergePartsOrderingIssueSurveyFunc,
  PartsOrderingIssuesSurveyDialog,
} from "../../components/Forms/PartsOrderingIssuesSurveyDialog";
import { usePartsOrderingIssuesSurvey } from "../../contexts/parts-ordering-issues-survey-context";

export const FOLLOW_UP_ALERT_MESSAGE = "A follow up service request must have outreach notes";

const getPaymentInput = (serviceCatalogueUsed, invoice) =>
  serviceCatalogueUsed === SERVICE_CATALOGUE_USED_SERVICES
    ? {
        subTotal: pipe(path(["priceInfo", "subTotal"], invoice), currency),
        partsTotal: pipe(path(["priceInfo", "partsTotal"], invoice), currency),
        laborTotal: pipe(path(["priceInfo", "laborTotal"], invoice), currency),
        feesTotal: pipe(path(["priceInfo", "feesTotal"], invoice), currency),
        discountTotal: pipe(path(["priceInfo", "combinedDiscountTotal"], invoice), currency),
        finalPartsTotal: pipe(path(["priceInfo", "finalPartsTotal"], invoice), currency),
        finalLaborTotal: pipe(path(["priceInfo", "finalLaborTotal"], invoice), currency),
        finalFeesTotal: pipe(path(["priceInfo", "finalFeesTotal"], invoice), currency),
        finalSubTotal: pipe(path(["priceInfo", "finalSubTotal"], invoice), currency),
        partsTax: pipe(path(["priceInfo", "partsTax"], invoice), currency),
        laborTax: pipe(path(["priceInfo", "laborTax"], invoice), currency),
        totalTax: pipe(path(["priceInfo", "totalTax"], invoice), currency),
        laborCost: pipe(path(["priceInfo", "finalLaborTotal"], invoice), currency),
        partsCost: pipe(path(["priceInfo", "finalPartsTotal"], invoice), currency),
        amount: pipe(path(["priceInfo", "amountDue"], invoice), currency),
        balanceDue: pipe(prop("balanceDue", invoice), currency),
        amountPaid: pipe(prop("amountPaid", invoice), currency),
      }
    : {
        laborCost: pipe(prop("laborCost", invoice), currency),
        partsCost: pipe(prop("partsCost", invoice), currency),
        partsTax: pipe(prop("partsTax", invoice), currency),
        laborTax: pipe(prop("laborTax", invoice), currency),
        subTotal: pipe(prop("subTotal", invoice), currency),
        totalTax: pipe(prop("totalTax", invoice), currency),
        partsTotal: pipe(prop("partsCost", invoice), currency),
        laborTotal: pipe(prop("laborCost", invoice), currency),
        feesTotal: currency(0),
        discountTotal: currency(0),
        finalPartsTotal: pipe(prop("partsCost", invoice), currency),
        finalLaborTotal: pipe(prop("laborCost", invoice), currency),
        finalFeesTotal: currency(0),
        finalSubTotal: pipe(prop("subTotal", invoice), currency),
        amount: pipe(prop("balanceDue", invoice), currency),
        balanceDue: pipe(propOr("", "balanceDue", invoice), currency) ?? null,
        amountPaid: pipe(propOr("", "amountPaid", invoice), currency) ?? null,
      };

const InvoiceDetailsPage = () => {
  const [open, setOpen, togglePartsSurvey] = useToggle();
  const { state } = usePartsOrderingIssuesSurvey();
  //const history = useHistory();
  const invoiceId = useEntityId();
  const { data, refetch } = useQuery<Query, QueryGetInvoiceArgs>(GET_INVOICE, {
    variables: {
      id: invoiceId,
    },
  });
  const { data: technicianGetMeData } = useQuery<Query>(TECHNICIAN_GET_ME);
  const dispatch = useDispatch();
  const [updateInvoice] = useMutation<Mutation, MutationUpdateInvoiceArgs>(UPDATE_INVOICE);
  //const [updateJob] = useMutation<Mutation, MutationUpdateJobArgs>(UPDATE_JOB);
  const invoice: Invoice = prop("getInvoice", data);
  const serviceCatalogueUsed = pathOr(DEFAULT_SERVICE_CATALOGUE_USED, ["serviceCatalogueUsed"], invoice);
  useEffect(() => {
    if (invoice) {
      const partsOrderingIssuesSurveys: PartsOrderingIssuesSurvey[] = pathOr(
        [],
        ["job", "partsOrderingIssuesSurveys"],
        invoice
      );
      const lastSurvey = last(partsOrderingIssuesSurveys);
      const invoiceIssuedDate = new Date(invoice.issuedDate);
      if (invoice.status === "Paid" && (isNil(lastSurvey) || !lastSurvey?.completed) && isToday(invoiceIssuedDate)) {
        setOpen(true);
      }
    }
  }, [invoice, setOpen]);
  const addPaymentInput = getPaymentInput(serviceCatalogueUsed, invoice);
  const hasPreJobChecklist = propEq("preJobCheckList", null);
  const hasPreJobChecklistV2 = propEq("preJobCheckListV2", null);
  if (!invoice) return null;
  const contactId = path<string>(["contact", "id"], invoice);
  const jobId = path<string>(["job", "id"], invoice);
  const { dateLeadCreated, followUpNeeded, outreachDate, outreachNotes, outreachTimeline, pipedriveDealId } =
    invoice.serviceFollowUp;
  const contact = path<Contact>(["contact"], invoice);
  const initialValues: UpdateInvoiceDetailsFormValues = {
    contact: invoice.contact,
    serviceLocation: invoice.serviceLocation,
    status: invoice.status,
    items: invoice.items,
    services: ejiServicesToFormValuesSpec(pathOr([], ["services"], invoice)),
    discounts: ejiDiscountsToFormValuesSpec(pathOr([], ["priceInfo", "discounts"], invoice)),
    priceInfo: ejiPriceInfoToFormValue(pathOr({}, ["priceInfo"], invoice)),
    serviceCatalogueUsed: serviceCatalogueUsed,
    market: invoice.market,
    technician: invoice.technician,
    privateNotes: invoice.privateNotes,
    customerMessage: invoice.customerMessage,
    issuedDate: invoice.issuedDate,
    dueDate: invoice.dueDate,
    taxable: invoice.taxable,
    jobId,
    jobNumber: path<string>(["job", "jobNumber"], invoice),
    year: pathOr("", ["vehicleInfo", "year"], invoice),
    extraInfo: pathOr("", ["vehicleInfo", "extraInfo"], invoice),
    symptoms: pathOr("", ["vehicleInfo", "symptoms"], invoice),
    vin: pathOr("", ["vehicleInfo", "vin"], invoice),
    rearPadLife: pathOr("", ["vehicleInfo", "rearPadLife"], invoice),
    odometer: pathOr("", ["vehicleInfo", "odometer"], invoice),
    model: pathOr("", ["vehicleInfo", "model"], invoice),
    make: pathOr("", ["vehicleInfo", "make"], invoice),
    licensePlate: pathOr("", ["vehicleInfo", "licensePlate"], invoice),
    frontPadLife: pathOr("", ["vehicleInfo", "frontPadLife"], invoice),
    preJobCheckList: !hasPreJobChecklist(invoice as any) ? preJobCheckListInitialValues(invoice) : null,
    preJobCheckListV2: !hasPreJobChecklistV2(invoice as any) ? preJobCheckListV2InitialValues(invoice) : null,
    serviceFollowUp: {
      followUpNeeded,
      outreachDate,
      outreachNotes,
      outreachTimeline,
    },
    proceedingWithRepairs: invoice.proceedingWithRepairs
  };

  const partsStores = pipe(
    pluck("partsStore", invoice.items),
    // adds the home part store in case its not there
    (stores) => {
      const homePartsStore = technicianGetMeData?.technicianGetMe?.homePartsStore;
      if (homePartsStore) {
        stores.push(homePartsStore);
      }
      return stores;
    },
    reject(anyPass([isEmpty, isNil])) as () => PartsStore[],
    uniqBy(prop("id")),
    (stores) => {
      const noPartsStoreFound: PartsStore = { vendor: "NA", name: "No parts store found", id: "", storeNumber: "" };
      return anyPass([isEmpty, isNil])(stores) ? [noPartsStoreFound] : stores;
    }
  );

  const onSubmit = async (values: UpdateInvoiceDetailsFormValues, _formikHelkpers) => {
    const diff = objectDiff(values, initialValues);
    const updates = updateInvoiceDetailsSpec(diff as any);
    const cleanedUpdates = cleanObject(updates);

    if (
      path(["serviceFollowUp", "followUpNeeded"], values) &&
      either(isNil, isEmpty)(path(["serviceFollowUp", "outreachNotes"], values))
    ) {
      showErrorAlert(dispatch, FOLLOW_UP_ALERT_MESSAGE);
    } else if (keys(cleanedUpdates)?.length) {
      await updateInvoice({
        variables: {
          id: invoiceId,
          updateInvoiceInput: cleanedUpdates,
        },
      });
      showSuccessAlert(dispatch, "Success");
    }
  };

  const invoiceNumber = prop("invoiceNumber", invoice);

  return (
    <Layout
      appBar={
        <InvoiceDetailsAppBar
          laborCost={addPaymentInput.laborCost.toString()}
          partsCost={addPaymentInput.partsCost.toString()}
          partsTax={addPaymentInput.partsTax.toString()}
          laborTax={addPaymentInput.laborTax.toString()}
          payer={invoice.contact?.fullName ?? "Unknown"}
          subTotal={addPaymentInput.subTotal.toString()}
          totalTax={addPaymentInput.totalTax.toString()}
          invoiceNumber={invoiceNumber}
          status={prop("status", invoice)}
          invoiceId={invoiceId}
          contactId={contactId}
          amount={addPaymentInput.amount.toString()}
          balanceDue={addPaymentInput?.balanceDue?.toString()}
          amountPaid={addPaymentInput?.amountPaid?.toString()}
          refetch={refetch}
          jobId={jobId}
          checkListsCreated={!!invoice?.preJobCheckListV2?.odometer}
          contact={contact}
          proceedingWithRepairs={invoice.proceedingWithRepairs}
        />
      }
    >
      <Paper>
        <UpdateInvoiceDetailsForm
          pinSaveToBottom={false}
          initialValues={initialValues}
          onSubmit={onSubmit}
          invoiceId={invoiceId}
          jobId={jobId}
          itemsEditable={invoice.status === "Draft"}
          amountPaid={prop("amountPaid", invoice)}
          balanceDue={addPaymentInput.amount.toString()}
        />
      </Paper>
      <PartsOrderingIssuesSurveyDialog
        initialValues={mergeDeepWith(
          mergePartsOrderingIssueSurveyFunc,
          state,
          last(invoice?.job?.partsOrderingIssuesSurveys ?? []) ?? {}
        )}
        jobId={jobId}
        existingSurveys={pathOr([], ["job", "partsOrderingIssuesSurveys"], invoice)}
        open={open}
        partsStores={partsStores}
        jobDone
      />
    </Layout>
  );
};

export default InvoiceDetailsPage;
