import { applySpec, path, prop } from "ramda";
import { UpdateInvoiceDetailsFormValues } from "../Forms/UpdateInvoiceDetailsForm";
import { itemsSpec, preJobCheckListItemSpec } from "../Jobs/jobDetailsSpec";
import { PreJobCheckListFormValues } from "../Jobs/PreJobCheckListDialog";
import {
  CreateInvoiceInput,
  EjiPricingConfig,
  EjiPricingConfigInput,
  OilChangeDateInput,
  OilChangeMileageInput,
  OilLifeListItem,
  PadThicknessInput,
  BrakePadsListItem,
  BrakePadsListItemInput,
  TireTreadListItem,
  TireTreadListItemInput,
  TireTreadDepthInput,
  OilLifeListItemInput,
  UpdateInvoiceInput,
  VendorUnitCostMultiplier,
  VendorUnitCostMultiplierInput,
} from "../../generated/nest-graphql";
import {
  ejiPromoCodesToFormValuesSpec,
  formValuesToEJIDiscountInputs,
  formValuesToEJIPromoCodeInputs,
  formValuesToEJIServiceInputsSpec,
} from "../../specs/ejiServicesSpec";
import { flow } from "fp-ts/lib/function";

export const updateInvoiceDetailsSpec = (formData: UpdateInvoiceDetailsFormValues): UpdateInvoiceInput =>
  invoiceDetailsSpec(formData);

export const createInvoiceDetailsSpec = (formData: UpdateInvoiceDetailsFormValues): CreateInvoiceInput =>
  invoiceDetailsSpec(formData);
export const emptyCheckList: PreJobCheckListFormValues = {} as PreJobCheckListFormValues;

export const pricingConfigToEjiPricingConfigInput = (pricingConfig: EjiPricingConfig) =>
  applySpec<EjiPricingConfigInput>({
    laborRate: prop("laborRate"),
    vendorUnitCostMultipliers: flow(
      prop("vendorUnitCostMultipliers"),
      vendorUnitCostMultipliersToVendorUnitCostMultiplierInputs
    ),
  })(pricingConfig);

export const vendorUnitCostMultipliersToVendorUnitCostMultiplierInputs = (
  vendorUnitCostMultipliers?: VendorUnitCostMultiplier[]
) =>
  vendorUnitCostMultipliers?.map(
    applySpec<VendorUnitCostMultiplierInput>({
      startRangePrice: prop("startRangePrice"),
      endRangePrice: prop("endRangePrice"),
      multiplier: prop("multiplier"),
    })
  );

export const oilLifeSpec = (oilLife: OilLifeListItem) =>
  applySpec<OilLifeListItemInput>({
    estimatedNextOilChangeTimeline: path(["estimatedNextOilChangeTimeline"]),
    nextOilChangeMileage: applySpec<OilChangeMileageInput>({
      mileage: path(["nextOilChangeMileage", "mileage"]),
      unavailable: path(["nextOilChangeMileage", "unavailable"]),
    }),
    nextOilChangeDate: applySpec<OilChangeDateInput>({
      date: path(["nextOilChangeDate", "date"]),
      unavailable: path(["nextOilChangeDate", "unavailable"]),
    }),
  })(oilLife);

export const tireTreadSpec = (tireTread: TireTreadListItem) =>
  applySpec<TireTreadListItemInput>({
    frontLeft: applySpec<TireTreadDepthInput>({
      depth: path(["frontLeft", "depth"]),
      unavailable: path(["frontLeft", "unavailable"]),
    }),
    frontRight: applySpec<TireTreadDepthInput>({
      depth: path(["frontRight", "depth"]),
      unavailable: path(["frontRight", "unavailable"]),
    }),
    rearLeft: applySpec<TireTreadDepthInput>({
      depth: path(["rearLeft", "depth"]),
      unavailable: path(["rearLeft", "unavailable"]),
    }),
    rearRight: applySpec<TireTreadDepthInput>({
      depth: path(["rearRight", "depth"]),
      unavailable: path(["rearRight", "unavailable"]),
    }),
  })(tireTread);

export const padThicknessSpec = (brakePads: BrakePadsListItem) =>
  applySpec<BrakePadsListItemInput>({
    padThickness: applySpec<PadThicknessInput>({
      thickness: path(["padThickness", "thickness"]),
      unavailable: path(["padThickness", "unavailable"]),
    }),
    condition: path(["condition"]),
  })(brakePads);

const invoiceDetailsSpec = (formData: UpdateInvoiceDetailsFormValues) => {
  const {
    year,
    taxable,
    technician,
    extraInfo,
    frontPadLife,
    licensePlate,
    make,
    model,
    odometer,
    rearPadLife,
    symptoms,
    vin,
    contact,
    market,
    jobId,
    estimate,
    serviceLocation,
    privateNotes,
    items,
    status,
    issuedDate,
    dueDate,
    customerMessage,
    preJobCheckList,
    preJobCheckListV2,
    services,
    serviceCatalogueUsed,
    discounts,
    promoCodes,
    priceInfo,
    serviceFollowUp,
    proceedingWithRepairs,
  } = formData;
  const {
    frontPads,
    frontRotors,
    rearPads,
    rearRotors,
    brakeFluid,
    brakeShoes,
    brakeDrums,
    calipers,
    shocksStruts,
    controlArms,
    wheelHubs,
    studs,
    lugnuts,
    sparkPlugs,
    carBattery,
    alternator,
    engineAirFilter,
    cabinAirFilter,
    otherBrakeParts,
    dashboardLights,
    oilLife,
    tireTread,
    frontPadsV2,
    rearPadsV2,
  } = preJobCheckList ?? emptyCheckList;
  return {
    contact: prop("id", contact),
    customerMessage,
    dueDate,
    issuedDate,
    technician: prop("id", technician),
    market,
    taxable,
    items: items && itemsSpec(items),
    privateNotes,
    serviceLocation,
    status,
    estimate,
    job: jobId,
    vehicleInfo: {
      year,
      extraInfo,
      frontPadLife,
      licensePlate,
      make,
      model,
      odometer,
      rearPadLife,
      symptoms,
      vin,
    },
    preJobCheckList: {
      frontPads: frontPads && preJobCheckListItemSpec(frontPads),
      frontPadsV2: frontPadsV2 && padThicknessSpec(frontPadsV2),
      frontRotors: frontRotors && preJobCheckListItemSpec(frontRotors),
      rearPads: rearPads && preJobCheckListItemSpec(rearPads),
      rearPadsV2: rearPadsV2 && padThicknessSpec(rearPadsV2),
      rearRotors: rearRotors && preJobCheckListItemSpec(rearRotors),
      brakeFluid: brakeFluid && preJobCheckListItemSpec(brakeFluid),
      brakeShoes: brakeShoes && preJobCheckListItemSpec(brakeShoes),
      brakeDrums: brakeDrums && preJobCheckListItemSpec(brakeDrums),
      calipers: calipers && preJobCheckListItemSpec(calipers),
      shocksStruts: shocksStruts && preJobCheckListItemSpec(shocksStruts),
      controlArms: controlArms && preJobCheckListItemSpec(controlArms),
      wheelHubs: wheelHubs && preJobCheckListItemSpec(wheelHubs),
      studs: studs && preJobCheckListItemSpec(studs),
      lugnuts: lugnuts && preJobCheckListItemSpec(lugnuts),
      sparkPlugs: sparkPlugs && preJobCheckListItemSpec(sparkPlugs),
      carBattery: carBattery && preJobCheckListItemSpec(carBattery),
      alternator: alternator && preJobCheckListItemSpec(alternator),
      engineAirFilter: engineAirFilter && preJobCheckListItemSpec(engineAirFilter),
      cabinAirFilter: cabinAirFilter && preJobCheckListItemSpec(cabinAirFilter),
      otherBrakeParts: otherBrakeParts && preJobCheckListItemSpec(otherBrakeParts),
      dashboardLights: dashboardLights && preJobCheckListItemSpec(dashboardLights),
      oilLife: oilLife && oilLifeSpec(oilLife),
      tireTread: tireTread && tireTreadSpec(tireTread),
    },
    preJobCheckListV2,
    services: formValuesToEJIServiceInputsSpec(services),
    serviceCatalogueUsed: serviceCatalogueUsed,
    discounts: formValuesToEJIDiscountInputs(discounts),
    promoCodes: formValuesToEJIPromoCodeInputs(promoCodes),
    serviceFollowUp: serviceFollowUp,
    proceedingWithRepairs,
  };
};
