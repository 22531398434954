import React from "react";
import { PreJobCheckListV2 } from "./types";
import { PreJobCheckListV2Form } from "./PreJobCheckListV2Form";
import { useInspectionDispatchV2 } from "../../contexts/inspection-context-v2";
import { useMutation, useQuery } from "@apollo/client";
import {
  Mutation,
  MutationUpdateInvoiceArgs,
  MutationUpdateJobArgs,
  MutationUpsertVehicleArgs,
  Query,
  QueryGetJobArgs,
  QueryGetVehicleArgs,
  UpdateJobInput,
  VehicleInput,
  VehicleType,
} from "../../generated/nest-graphql";
import { UPDATE_JOB } from "../../graphql/mutations/updateJob";
import { UPDATE_INVOICE } from "../../graphql/mutations/updateInvoice";
import { flow } from "lodash";
import { preJobCheckListV2UpdateJobSpec } from "../Jobs/jobDetailsSpec";
import { keys } from "ramda";
import { showSuccessAlert } from "../../actions";
import { useDispatch } from "../../contexts/snackbar-context";
import { UPSERT_VEHICLE } from "../../graphql/mutations/upsertVehicle";
import { GET_JOB } from "../../graphql/queries/getJob";
import { GET_VEHICLE } from "../../graphql/queries/getVehicles";

export type PreJobCheckListV2FormValues = PreJobCheckListV2 & {
  odometer: string;
};

export const mapVehicleTypeToVehicleInput = (vehicle: VehicleType): VehicleInput => ({
  contactId: vehicle.contactId,
  make: vehicle.make,
  model: vehicle.model,
  year: vehicle.year,
  name: vehicle.name,
  subModel: vehicle.subModel,
  id: vehicle.id,
  licensePlate: vehicle.licensePlate,
  licensePlateState: vehicle.licensePlateState,
  hasDrumBrakes: vehicle.vehicleTechnicalInfo.hasDrumBrakes,
  notes: vehicle.notes,
  vin: vehicle.vin,
  inactive: vehicle.inactive,
  odometer: vehicle.odometer,
  wheelTorque: vehicle.vehicleTechnicalInfo.wheelTorque,
  parkingBrake: vehicle.vehicleTechnicalInfo.parkingBrake,
  frontRotorsDiscardThicknessInches: vehicle.vehicleTechnicalInfo.frontRotorsDiscardThicknessInches,
  rearRotorsDiscardThicknessInches: vehicle.vehicleTechnicalInfo.rearRotorsDiscardThicknessInches,
});

export const PreJobCheckListV2Dialog = ({
  jobId,
  invoiceId,
  open,
  onClose,
  initialValues,
}: {
  jobId: string;
  invoiceId?: string;
  open: boolean;
  onClose: () => void;
  initialValues: PreJobCheckListV2FormValues;
}) => {
  const dispatch = useDispatch();
  const inspectionDispatch = useInspectionDispatchV2();
  const [updateJob] = useMutation<Mutation, MutationUpdateJobArgs>(UPDATE_JOB);
  const [updateInvoice] = useMutation<Mutation, MutationUpdateInvoiceArgs>(UPDATE_INVOICE);
  const [upsertVehicle] = useMutation<Mutation, MutationUpsertVehicleArgs>(UPSERT_VEHICLE);

  const { data, loading } = useQuery<Query, QueryGetJobArgs>(GET_JOB, {
    variables: {
      id: jobId,
    },
    fetchPolicy: "cache-first",
    returnPartialData: true,
  });

  const vehicleId = data?.getJob?.vehicleId;

  const { data: vehicleData, loading: loadingVehicleData } = useQuery<Query, QueryGetVehicleArgs>(GET_VEHICLE, {
    variables: {
      id: vehicleId,
    },
    skip: !vehicleId,
    fetchPolicy: "cache-first",
    returnPartialData: true,
  });

  const vehicle = vehicleData?.getVehicle;

  const initValues: PreJobCheckListV2FormValues = {
    ...initialValues,
    licensePlate: vehicle?.licensePlate || initialValues.licensePlate,
    licensePlateState: vehicle?.licensePlateState || initialValues.licensePlateState,
  };

  const updateOnClose = (values: PreJobCheckListV2FormValues) => {
    inspectionDispatch({ type: "UPDATE_FULL_INSPECTION", payload: values });
    onClose();
  };
  const onSubmit = async (values: PreJobCheckListV2FormValues) => {
    inspectionDispatch({ type: "UPDATE_FULL_INSPECTION", payload: values });
    const input: UpdateJobInput = flow(preJobCheckListV2UpdateJobSpec)(values as PreJobCheckListV2FormValues);
    await upsertVehicle({
      variables: {
        vehicleInputData: mapVehicleTypeToVehicleInput({
          ...vehicle,
          odometer: values.odometer,
          licensePlate: values.licensePlate,
          licensePlateState: values.licensePlateState,
        }),
      },
    });

    await updateJob({
      variables: {
        id: jobId,
        updateJobInput: input,
      },
    });
    if (invoiceId && keys(values).length && input) {
      updateInvoice({
        variables: {
          id: invoiceId,
          updateInvoiceInput: input,
        },
      });
    }
    onClose();
    showSuccessAlert(dispatch, "Success");
  };

  if (loading || loadingVehicleData) {
    return null;
  }

  return (
    <PreJobCheckListV2Form
      key={"pre-job-2"}
      initialValues={initValues}
      onSubmit={onSubmit}
      open={open}
      onClose={updateOnClose}
      jobId={jobId}
    />
  );
};
