import * as React from "react";
import { CSSProperties } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { not } from "ramda";

type MuiMaxWidth = "xs" | "sm" | "md" | "lg" | "xl";

export const NuModal: React.FC<{
  style?: CSSProperties;
  isOpen: boolean;
  maxWidth?: MuiMaxWidth;
  actions?: React.ReactNode;
  title?: string;
  fullScreen?: boolean;
}> = ({ isOpen, children, maxWidth, actions, title, fullScreen = false }) => {
  const modalType = {
    fullScreen: fullScreen,
    fullWidth: not(fullScreen),
  };
  return (
    <Dialog {...modalType} maxWidth={maxWidth} open={isOpen} PaperProps={{ variant: "outlined" }}>
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent> {children} </DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};
