import React from "react";
import { FullScreenDialog } from "../FullScreenDialog";
import { Paper, Container, Typography, Box, Button } from "@material-ui/core";
import { Job, Mutation, MutationCloseZeroDollarInvoiceArgs } from "../../generated/nest-graphql";
import { CLOSE_ZERO_DOLLAR_INVOICE } from "../../graphql/mutations/closeJobAndInvoice";
import { useMutation } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import { useHistory } from "react-router-dom";
import { useDispatch } from "../../contexts/snackbar-context";
import { showErrorAlert, showSuccessAlert } from "../../actions";

type CompleteJobDialogProps = {
  invoiceId: string;
  contactId: string;
  open: boolean;
  onClose: () => void;
  refetch: any;
  job: Job;
};

export const CompleteJobDialog = ({ invoiceId, open, onClose, job }: CompleteJobDialogProps) => {
  const [closeZeroDollarInvoice] = useMutation<Mutation, MutationCloseZeroDollarInvoiceArgs>(CLOSE_ZERO_DOLLAR_INVOICE);
  const dispatch = useDispatch();
  const closeJobAndInvoice = async () => {
    try {
      if(invoiceId){
        await closeZeroDollarInvoice({
          variables: {
            closeZeroDollarInvoiceInput: {
              invoiceId,
            },
          },
        });
        showSuccessAlert(dispatch, "Success");
        onClose();
        push(`/jobs/${jobId}`);
      }else{
        showErrorAlert(dispatch, "Invoice Error")
      }

    } catch (e) {
      showErrorAlert(dispatch, "Error")
      Sentry.captureException(e, {
        extra: {
          invoiceId,
        },
      });
    } finally{
      showSuccessAlert(dispatch, "Success");
      onClose();
      push(`/jobs/${jobId}`);
    }
  };
  const { push } = useHistory();
  const { id: jobId, type, warrantyCheckReason, warrantyCheckTechReason } = job;

  const handleAddWarrantyCheckReason = ()=>{
    onClose();
    push(`/jobs/${jobId}?openJobTypeModal=true`);
  }

  const isWarrantyJob = type === 'Warranty Check';
  const hasWarrantyCheckReason = !!warrantyCheckReason || !!warrantyCheckTechReason;
  const isJobTypeValid = isWarrantyJob && hasWarrantyCheckReason || !isWarrantyJob;


  return (
    <FullScreenDialog title={"Complete Job and Invoice"} open={open} onClose={onClose}>
      <Paper>
        <Container className={"py-4"}>
          <Box style={{ textAlign: "center" }}>
            {!isJobTypeValid && (<>
              <div className={"pb-8"}>
                <div className={"pb-2"}>You must add a Warranty Check Reason.</div>
                <Button
                  size={"large"}
                  variant={"contained"}
                  color={"primary"}
                  onClick={handleAddWarrantyCheckReason}
                  className={"w-full"}
                >
                  {"Add Warranty Check Reason"}
                </Button>
              </div>
            </>)}
            {isJobTypeValid && invoiceId && (<>
              <Box>
                <Typography variant="h5">Are you sure you want to complete a job with 0$ invoice?</Typography>
              </Box>
              <Box mb={3}>
                <Typography variant="subtitle1">Customer will still recieve a receipt if you do this.</Typography>
              </Box>
              <Box>
                <Button onClick={closeJobAndInvoice} size="large" variant="contained" color="primary">
                  Yes
                </Button>
                <Box display="inline" margin={4}></Box>
                <Button onClick={onClose} size="large" variant="contained" color="secondary">
                  No
                </Button>
              </Box>
            </>)}
          </Box>
        </Container>
      </Paper>
    </FullScreenDialog>
  );
};
