import * as React from "react";
import { Dialog, Theme } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import AppBar from "@material-ui/core/AppBar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { createStyles } from "@material-ui/styles";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      flex: 1,
    },
    name: {
      fontWeight: 700,
    },
    paper: {
      backgroundColor: "black",
    },
  })
);

export type FullScreenDialogProps = {
  title: string | React.ReactElement;
  open: boolean;
  onClose?: () => void;
  headerPosition?: "fixed" | "relative";
};

export const FullScreenDialog: React.FC<FullScreenDialogProps> = ({
  title,
  children,
  open,
  onClose,
  headerPosition,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      fullScreen
      classes={{
        paper: classes.paper,
      }}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      {open && (
        <>
          <AppBar className={headerPosition ? `${headerPosition}` : classes.appBar}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                {title}
              </Typography>
              {onClose && (
                <IconButton color="inherit" onClick={onClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
              )}
            </Toolbar>
          </AppBar>
          {children}
        </>
      )}
    </Dialog>
  );
};
