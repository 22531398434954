import gql from "graphql-tag";

export const partDetailFields = `
  id
  partId
  productName
  label
  vendorUnitCost
  type`;

export const GET_PART_CATALOGUE = gql`
  query getPartCatalogue {
    getPartCatalogue {
      ${partDetailFields}
    }
  }
`;
