import * as React from "react";
import { useField } from "formik";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
//import { labelStyles } from "../../material-ui-styles";
import { path } from "ramda";

export const CheckBoxField: React.FC<{
  name: string;
  label?: string;
  disabled?: boolean;
  postOnChange?: (value: boolean) => void;
}> = ({ name, label = "", disabled, postOnChange }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(name);
  //const classes = labelStyles();
  return (
    <div style={{ maxWidth: "fit-content" }}>
      <label className="text-gray-500 font-bold">
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              {...field}
              disabled={disabled}
              checked={field.value}
              onChange={(event: object) => {
                const checked = path<boolean>(["target", "checked"], event);

                helpers.setValue(checked);
                postOnChange && postOnChange(checked);
              }}
            />
          }
          label={
            <Typography variant={"body1"}>
              {label}
            </Typography>
          }
        />
      </label>
    </div>
  );
};

export const CheckBoxFieldRHF: React.FC<{
  name: string;
  label?: string;
  value: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
}> = ({ name, label = "", disabled, onChange, value }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //const classes = labelStyles();
  return (
    <div style={{ maxWidth: "fit-content" }}>
      <label className="text-gray-500 font-bold">
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={value}
              disabled={disabled}
              onChange={(event: object) => {
                const checked = path<boolean>(["target", "checked"], event);
                onChange(checked);
              }}
            />
          }
          label={
            <Typography variant={"body1"}>
              {label}
            </Typography>
          }
        />
      </label>
    </div>
  );
};
