import React, { useState, useEffect } from "react";
import { PreJobCheckListFormValues } from "../Jobs/PreJobCheckListDialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { TextField } from "./fields/TextField";
import { Form, Formik, yupToFormErrors } from "formik";
import { Paper } from "@material-ui/core";
import { SubmitButton } from "../Buttons/SubmitButton";
import * as Yup from "yup";
import { includes, mergeDeepWithKey } from "ramda";
import { PartConditionOptions } from "./fields/PartConditionOptions";
import { PartConditionListItem } from "../ListItems/PartConditionListItem";
import Box from "@material-ui/core/Box";
import { OilLifeDropdownListItem } from "../ListItems/OilLifeDropdownListItem";
import { FullScreenDialog } from "../FullScreenDialog";
import { useInspectionReport } from "../../contexts/inspection-context";
import { mergePreJobChecklistFunc } from "../Jobs/JobStatusSection";
import { BrakePadsDropdownListItem } from "../ListItems/BrakePadsDropdownListItem";
// import { TireTreadDropdownListItem } from "../ListItems/TireTreadDropdownListItem";

const needInfoConditions = [
  "Yellow (Has wear, not in need of immediate repair)",
  "Red (Needs immediate repair)",
  "Warning lights on",
];

const startOfToday = new Date();
startOfToday.setHours(0, 0, 0, 0);
const endOfToday = new Date();
endOfToday.setHours(23, 59, 59, 59);

const checkListItemsSchema = Yup.object({
  partCondition: Yup.string().required("Required").nullable(),
  additionalInfo: Yup.string().when("partCondition", (value) =>
    includes(value, needInfoConditions)
      ? Yup.string().required("Additional Notes is a required field")
      : Yup.string().nullable()
  ),
});

const oilLifeSchema = Yup.object().shape({
  estimatedNextOilChangeTimeline: Yup.mixed().oneOf([
    "Now / Past Due",
    "1 month",
    "2 months",
    "3 months",
    "4 months",
    "5 months",
    "6 months",
    "Unknown",
  ]),
  nextOilChangeMileage: Yup.object().shape(
    {
      mileage: Yup.string().when("unavailable", {
        is: (unavailable) => unavailable === false || unavailable === null,
        then: Yup.string().required(),
        otherwise: Yup.string().nullable(),
      }),
      unavailable: Yup.boolean().when("mileage", {
        is: (mileage) => mileage === null || mileage === undefined || mileage?.length === 0,
        then: Yup.boolean().oneOf([true]).required(),
        otherwise: Yup.boolean().nullable(),
      }),
    },
    [["mileage", "unavailable"]]
  ),
  nextOilChangeDate: Yup.object().shape(
    {
      date: Yup.date().when("unavailable", {
        is: (unavailable) => unavailable === false || unavailable === null,
        then: Yup.date().required(),
        otherwise: Yup.date().nullable(),
      }),
      unavailable: Yup.boolean().when("date", {
        is: (date) => date === null || date === undefined || date?.length === 0,
        then: Yup.boolean().oneOf([true]).required(),
        otherwise: Yup.boolean().nullable(),
      }),
    },
    [["date", "unavailable"]]
  ),
});

// const tireTreadDepthSchema = Yup.object().shape(
//   {
//     depth: Yup.string().when("unavailable", {
//       is: (unavailable) => unavailable === false || unavailable === null,
//       then: Yup.string().required(),
//       otherwise: Yup.string().nullable(),
//     }),
//     unavailable: Yup.boolean().when("depth", {
//       is: (depth) => depth === null || depth === undefined || depth?.length === 0,
//       then: Yup.boolean().oneOf([true]).required(),
//       otherwise: Yup.boolean().nullable(),
//     }),
//   },
//   [["depth", "unavailable"]]
// );

// const tireTreadSchema = Yup.object().shape({
//   frontLeft: tireTreadDepthSchema,
//   frontRight: tireTreadDepthSchema,
//   rearLeft: tireTreadDepthSchema,
//   rearRight: tireTreadDepthSchema,
// });

const brakePadsSchema = Yup.object().shape({
  padThickness: Yup.object().shape(
    {
      thickness: Yup.string().when("unavailable", {
        is: (unavailable) => unavailable === false || unavailable === null,
        then: Yup.string().required(),
        otherwise: Yup.string().nullable(),
      }),
      unavailable: Yup.boolean().when("thickness", {
        is: (thickness) => thickness === null || thickness === undefined || thickness?.length === 0,
        then: Yup.boolean().oneOf([true]).required(),
        otherwise: Yup.boolean().nullable(),
      }),
    },
    [["thickness", "unavailable"]]
  ),
  condition: Yup.mixed().oneOf(["Good condition", "Cracked", "Grooved", "Uneven wear", "Metal to metal"]),
});

const schema = Yup.object().shape(
  {
    odometer: Yup.string().required().nullable(),
    frontPads: Yup.object().when("frontPads", {
      is: (frontPads) => !!frontPads?.partCondition,
      then: checkListItemsSchema,
      otherwise: Yup.object().nullable(),
    }),
    frontPadsV2: Yup.object().when("frontPads", {
      is: (frontPads) => !!frontPads?.partCondition,
      then: Yup.object().nullable(),
      otherwise: brakePadsSchema,
    }),
    frontRotors: checkListItemsSchema,
    rearPads: Yup.object().when("rearPads", {
      is: (rearPads) => !!rearPads?.partCondition,
      then: checkListItemsSchema,
      otherwise: Yup.object().nullable(),
    }),
    rearPadsV2: Yup.object().when("rearPads", {
      is: (rearPads) => !!rearPads?.partCondition,
      then: Yup.object().nullable(),
      otherwise: brakePadsSchema,
    }),
    rearRotors: checkListItemsSchema,
    brakeFluid: checkListItemsSchema,
    brakeShoes: checkListItemsSchema,
    brakeDrums: checkListItemsSchema,
    calipers: checkListItemsSchema,
    shocksStruts: checkListItemsSchema,
    controlArms: checkListItemsSchema,
    wheelHubs: checkListItemsSchema,
    studs: checkListItemsSchema,
    lugnuts: checkListItemsSchema,
    sparkPlugs: Yup.object().when("sparkPlugs", {
      is: (sparkPlugs) => !!sparkPlugs?.partCondition,
      then: checkListItemsSchema,
      otherwise: Yup.object().nullable(),
    }),
    carBattery: checkListItemsSchema,
    otherBrakeParts: Yup.object().when("otherBrakeParts", {
      is: (otherBrakeParts) => !!otherBrakeParts?.partCondition,
      then: checkListItemsSchema,
      otherwise: Yup.object().nullable(),
    }),
    dashboardLights: checkListItemsSchema,
    oilLife: oilLifeSchema,
    // tireTread: tireTreadSchema,
  },
  [
    ["frontPads", "frontPads"],
    ["rearPads", "rearPads"],
    ["sparkPlugs", "sparkPlugs"],
    ["otherBrakeParts", "otherBrakeParts"],
  ]
);

export const PreJobCheckListForm: React.FC<{
  initialValues: PreJobCheckListFormValues;
  onSubmit: (values: PreJobCheckListFormValues) => void;
  open: boolean;
  onClose: (param) => void;
}> = ({ initialValues, onSubmit, open, onClose }) => {
  const [optionName, setOptionName] = useState(null);
  const [initialErrorsState, setInitialErrorsState] = useState({});
  const { state } = useInspectionReport();
  useEffect(() => {
    const merged = mergePreJobChecklistFunc(state, initialValues);
    schema
      .validate(merged, { abortEarly: false, recursive: true })
      .then((res) => {
        setInitialErrorsState({});
      })
      .catch((res) => {
        const errorObj = yupToFormErrors(res);
        setInitialErrorsState(errorObj);
      });
  }, [state, initialValues]);

  const isFrontPadsV1 = !!initialValues.frontPads?.partCondition;
  const isRearPadsV1 = !!initialValues.rearPads?.partCondition;
  const hasExistingOtherBrakeParts = !!initialValues.otherBrakeParts?.partCondition;
  const hasExistingSparkPlugs = !!initialValues.sparkPlugs?.partCondition;

  return (
    <Formik<PreJobCheckListFormValues>
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnMount
      validateOnBlur
      enableReinitialize
      initialErrors={initialErrorsState}
    >
      {({ values, isValid, isSubmitting }) => {
        return (
          <FullScreenDialog title={"Inspection"} open={open} onClose={() => onClose(values)} headerPosition={"fixed"}>
            <Paper className="pt-20">
              <PartConditionOptions setOptionName={setOptionName} name={optionName} />
              <Form>
                <Box className={"px-4"}>
                  <List component="nav">
                    {isFrontPadsV1 ? (
                      <PartConditionListItem name={"frontPads"} label={"Front Pads"} />
                    ) : (
                      <BrakePadsDropdownListItem name={"frontPadsV2"} label={"Front Pads"} />
                    )}
                    <PartConditionListItem name={"frontRotors"} label={"Front Rotors"} />
                    {isRearPadsV1 ? (
                      <PartConditionListItem name={"rearPads"} label={"Rear Pads"} />
                    ) : (
                      <BrakePadsDropdownListItem name={"rearPadsV2"} label={"Rear Pads"} />
                    )}
                    <PartConditionListItem name={"rearRotors"} label={"Rear Rotors"} />
                    <PartConditionListItem name={"brakeFluid"} label={"Brake Fluid"} />
                    <PartConditionListItem name={"brakeShoes"} label={"Brake Shoes"} />
                    <PartConditionListItem name={"brakeDrums"} label={"Brake Drums"} />
                    <PartConditionListItem name={"calipers"} label={"Calipers"} />
                    <PartConditionListItem name={"shocksStruts"} label={"Shocks & Struts"} />
                    <PartConditionListItem name={"controlArms"} label={"Control Arms"} />
                    <PartConditionListItem name={"wheelHubs"} label={"Wheel Hubs"} />
                    <PartConditionListItem name={"studs"} label={"Wheel Studs"} />
                    <PartConditionListItem name={"lugnuts"} label={"Lug Nuts"} />
                    {hasExistingSparkPlugs && <PartConditionListItem name={"sparkPlugs"} label={"Spark Plugs"} />}
                    <PartConditionListItem name={"carBattery"} label={"Battery"} />
                    {hasExistingOtherBrakeParts && (
                      <PartConditionListItem name={"otherBrakeParts"} label={"Other Brake Parts"} />
                    )}
                    <PartConditionListItem name={"dashboardLights"} label={"Dashboard Lights"} />
                    <OilLifeDropdownListItem name="oilLife" label="Oil Life" />
                    {/* <TireTreadDropdownListItem name={"tireTread"} label={"Tire Tread"} /> */}
                    <ListItem className={"mb-2"}>
                      <TextField label={"Odometer"} name={"odometer"} inputMode={"numeric"} />
                    </ListItem>
                    <SubmitButton isSubmitting={isSubmitting} isValid={isValid} label={"Submit"} />
                  </List>
                </Box>
              </Form>
            </Paper>
          </FullScreenDialog>
        );
      }}
    </Formik>
  );
};
