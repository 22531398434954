import { useQuery } from "@apollo/client";
import { Button, CircularProgress, TextField } from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { pipe } from "fp-ts/lib/function";
import { head, prop } from "ramda";
import * as React from "react";
import { useState, useEffect } from "react";
import { PossibleEjiService, Query, Service } from "../../generated/nest-graphql";
import { GET_SERVICE_CATALOGUE } from "../../graphql/queries/getServiceCatalogue";
import { TECHNICIAN_GET_ME } from "../../graphql/queries/technicianGetMe";
import { possibleEJIServicesToFormValuesSpec } from "../../specs/ejiServicesSpec";
import { showErrorAlert } from "../../actions";
import { useDispatch } from "../../contexts/snackbar-context";

interface PossibleEJIServiceWithAddOnCapabilities extends PossibleEjiService {
  allowOnSideAddOn: boolean;
}

export const SelectServiceFormSection: React.FC<{
  setEditedServiceAndRecalculatePrice: any;
  servicesList: PossibleEjiService[];
}> = ({ setEditedServiceAndRecalculatePrice, servicesList }) => {
  const dispatch = useDispatch();
  const [selectedService, setSelectedService] = useState<PossibleEJIServiceWithAddOnCapabilities>();
  
  const {
    data: serviceCatalogueData,
    loading: loadingServiceCatalogue,
    error: errorServiceCatalogue,
  } = useQuery<Query, {}>(GET_SERVICE_CATALOGUE);
  const {
    data: technicianGetMeData,
    loading: loadingTechnicianGetMe,
    error: errorTechnicianGetMe,
  } = useQuery<Query>(TECHNICIAN_GET_ME);

  useEffect(() => {
    const error = errorServiceCatalogue;
    if (error) {
      showErrorAlert(dispatch, error.message);
    }
  }, [errorServiceCatalogue, dispatch]);

  const loading = loadingServiceCatalogue || loadingTechnicianGetMe;
  const noData =!loading && (!serviceCatalogueData || !technicianGetMeData);
  
  if (loading) {
    return (
      <div className={"flex flex-row justify-center"}>
        <CircularProgress />
      </div>
    );
  }

  if (noData) {
    return (
      <div className={"flex flex-row justify-center"}>
        <h2>Error loading services data</h2>
      </div>
    );
  }
  const services = prop("getServiceCatalogue")(serviceCatalogueData as any) as Service[];
  const servicesListWithAddonCapabilities = servicesList.map((possibleService) =>
    pipe(
      // this is a hack so that we dont have to include allowOnSiteAddOn for all possible services
      services.find((service) => service.id === possibleService.service),
      (matchingService): PossibleEJIServiceWithAddOnCapabilities => ({
        ...possibleService,
        allowOnSideAddOn: matchingService?.allowOnSiteAddOn ?? false,
      })
    )
  );
  const homePartsStore = technicianGetMeData?.technicianGetMe?.homePartsStore;
  return (
    <div>
      <Autocomplete
        value={selectedService?.name ?? ""}
        onChange={(_, selectedOption) => {
          setSelectedService(
            servicesListWithAddonCapabilities.find((service) => service.name === selectedOption) ?? null
          );
        }}
        options={servicesListWithAddonCapabilities
          .filter((service) => service.allowOnSideAddOn === true)
          .map((service) => service.name)}
        getOptionLabel={(option) => option}
        style={{ width: "100%" }}
        renderInput={(params) => <TextField {...params} label="Select Service" variant="outlined" />}
      />
      <div className={"pt-4"}>
        <Button
          variant="outlined"
          disabled={!selectedService}
          style={{ minWidth: "100%" }}
          startIcon={<AddCircleOutline />}
          onClick={() => handleClick(selectedService, setEditedServiceAndRecalculatePrice, homePartsStore)}
        >
          Confirm Selection
        </Button>
      </div>
    </div>
  );
};

const handleClick = (
  selectedService: PossibleEJIServiceWithAddOnCapabilities,
  setEditedServiceAndRecalculatePrice: any,
  homePartsStore: any
) => {
  const ejiService: PossibleEjiService = {
    ...selectedService,
    items: selectedService.items
      .filter(({ behavior }) => behavior === "Default")
      .map((item) => ({
        ...item,
        orderItem: {
          ...item.orderItem,
          partsStore: homePartsStore ?? null,
        },
      })),
  };
  pipe([ejiService], possibleEJIServicesToFormValuesSpec, head, setEditedServiceAndRecalculatePrice);
};
