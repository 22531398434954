import cloneDeep from "lodash.clonedeep";
import React, { createContext, Dispatch, Reducer, useContext, useReducer } from "react";
import { PreJobCheckListV2FormValues } from "../components/PreJobChecklistV2/PreJobCheckListV2Dialog";

export const inspectedServicesInitialState: PreJobCheckListV2FormValues["inspectedServices"] = {
  frontLeftCaliper: {
    included: false,
    notes: "",
    colorGrade: "",
  },
  frontRightCaliper: {
    included: false,
    notes: "",
    colorGrade: "",
  },
  rearLeftCaliper: {
    included: false,
    notes: "",
    colorGrade: "",
  },
  rearRightCaliper: {
    included: false,
    notes: "",
    colorGrade: "",
  },
  frontLeftHubAssemblyBearing: {
    included: false,
    notes: "",
    colorGrade: "",
  },
  frontRightHubAssemblyBearing: {
    included: false,
    notes: "",
    colorGrade: "",
  },
  rearLeftHubAssemblyBearing: {
    included: false,
    notes: "",
    colorGrade: "",
  },
  rearRightHubAssemblyBearing: {
    included: false,
    notes: "",
    colorGrade: "",
  },
};

export const preJobCheckListInitialState: PreJobCheckListV2FormValues = {
  odometer: "",
  licensePlate: "",
  licensePlateState: "",
  brakeFluid: {
    colorGrade: "",
    level: "",
    ppm: "",
  },
  frontPads: {
    condition: [],
    padsThickness: "",
    innerPadThickness: "",
    outerPadThickness: "",
  },
  rearPads: {
    condition: [],
    padsThickness: "",
    innerPadThickness: "",
    outerPadThickness: "",
  },
  frontRotors: {
    condition: [],
    thicknessInches: "",
  },
  usesRearShoesAndDrums: false,
  rearRotors: {
    condition: [],
    thicknessInches: "",
  },
  rearShoes: {
    colorGrade: "",
    condition: [],
  },
  rearDrums: {
    colorGrade: "",
    condition: [],
  },
  inspectedServices: inspectedServicesInitialState,
  privateNotes: "",
  coldCrankingAmps: "",
  withoutCCA: false,
  batteryHealth: "",
  withoutBatteryHealth: false,
};

const InspectionReportV2StateContext = createContext<{
  state: PreJobCheckListV2FormValues;
  dispatch: Dispatch<DispatchAction>;
}>({
  state: cloneDeep(preJobCheckListInitialState),
  dispatch: () => null,
});

export type ActionUpdateFullInspection = {
  type: "UPDATE_FULL_INSPECTION";
  payload: Partial<PreJobCheckListV2FormValues>;
};

export type DispatchAction = ActionUpdateFullInspection;

const reducer: Reducer<PreJobCheckListV2FormValues, DispatchAction> = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "UPDATE_FULL_INSPECTION": {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      throw new Error(`Unhandled Action: ${action}`);
  }
};

export const InspectionProviderV2: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, cloneDeep(preJobCheckListInitialState));

  return (
    <InspectionReportV2StateContext.Provider value={{ state, dispatch }}>
      {children}
    </InspectionReportV2StateContext.Provider>
  );
};

export const useInspectionReportV2 = () => useContext(InspectionReportV2StateContext);
export const useInspectionDispatchV2 = () => {
  const { dispatch } = useContext(InspectionReportV2StateContext);
  return dispatch;
};
