import * as React from "react";
import { contains, equals } from "ramda";
import { TextOnWayButton } from "./TextOnWayButton";
import { StartJobButton } from "./StartJobButton";
import { PreJobCheckListFormValues } from "./PreJobCheckListDialog";
import { Contact } from "../../generated/nest-graphql";
import { PreJobCheckListV2FormValues } from "../PreJobChecklistV2/PreJobCheckListV2Dialog";
import { OpenInspectionButton } from "./OpenInspectionButton";

export const JobActionButton: React.FC<{
  jobId: string;
  jobStatus: string;
  jobType: string;
  message: string;
  phoneNumber: string;
  invoiceId: string;
  preJobCheckListInitialValues: PreJobCheckListFormValues;
  preJobChecklistV2InitialValues: PreJobCheckListV2FormValues;
  contact: Contact;
  createdAt: string;
}> = ({ jobId, invoiceId, jobStatus, message, phoneNumber, preJobCheckListInitialValues, contact, createdAt }) => {
  if (contains(jobStatus, ["New", "New: Scheduled", "Open", "Scheduled", "Self-Scheduling Link Sent"])) {
    return <TextOnWayButton jobId={jobId} message={message} phoneNumber={phoneNumber} />;
  }
  if (equals("Open: En Route", jobStatus)) {
    return <StartJobButton jobId={jobId} />;
  }
  const isLegacyJob = new Date(createdAt) < new Date("2024/05/12");
  if (isLegacyJob) {
    return (
      <OpenInspectionButton
        jobId={jobId}
        invoiceId={invoiceId}
        preJobCheckListInitialValues={preJobCheckListInitialValues}
        contact={contact}
      />
    );
  }

  return <div />;
};
