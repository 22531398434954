import React from "react";
import { Paper } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { FullScreenDialog } from "../FullScreenDialog";
import { Form, Formik } from "formik";
import { FINAL_RECOMENDATIONS, SERVICES, WITH_ESTIMATE_TIMELINE } from "./constants";
import { SubmitButton } from "../Buttons/SubmitButton";
import { ToggleButtonGroup } from "../Forms/fields/ToogleButtonGroup";
import { useQuery } from "@apollo/client";
import { GET_JOB_FOR_INSPECTION } from "../../graphql/queries/getJob";
import { Query, QueryGetJobArgs } from "../../generated/nest-graphql";
import { checkListSchema } from "./validation";
import { PostJobCheckListFormValues } from "./PostJobCheckListDialog";
import { DateInput } from "../Forms/fields/DateInput";
import { TextField } from "../Forms/fields/TextField";
import { CheckboxesField } from "../Forms/fields/CheckboxesField";

export const PostJobCheckListForm: React.FC<{
  onSubmit: (values) => void;
  open: boolean;
  onClose: (values) => void;
  jobId: string;
  initialValues: PostJobCheckListFormValues;
  proceedingWithRepairs?: boolean;
}> = ({ onSubmit, open, onClose, jobId, initialValues, proceedingWithRepairs }) => {
  const { data } = useQuery<Query, QueryGetJobArgs>(GET_JOB_FOR_INSPECTION, {
    fetchPolicy: "cache-first",
    variables: {
      id: jobId,
    },
    skip: !jobId,
  });
  return (
    <Formik<PostJobCheckListFormValues>
      validationSchema={checkListSchema(proceedingWithRepairs)}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnMount
      validateOnBlur
      enableReinitialize
    >
      {({ values, isValid, isSubmitting, setFieldValue, errors }) => {
        return (
          <FullScreenDialog
            title={"Inspection"}
            open={open}
            onClose={() => {
              onClose(values);
            }}
            headerPosition={"fixed"}
          >
            <Paper className="pt-20">
              <Form className="h-full">
                <Box className={"px-4 h-full"}>
                  {proceedingWithRepairs && (
                    <div className="flex flex-col p-4 border-[1px] rounded-lg mb-4">
                      <h2 className="text-lg mb-4">
                        Are there any outstanding brake repair needs for this vehicle (not including any repairs
                        completed today)
                      </h2>
                      <div className="flex flex-row">
                        <ToggleButtonGroup
                          name="needOutstandingRepair"
                          items={[
                            {
                              label: "Yes",
                              value: true,
                            },
                            {
                              label: "No",
                              value: false,
                            },
                          ]}
                          activeBackground="#f04e23"
                          background="#FFF2"
                        />
                      </div>
                    </div>
                  )}
                  {(!proceedingWithRepairs || values["needOutstandingRepair"]) && (
                    <>
                      <div className="flex flex-col p-4 border-[1px] rounded-lg mb-4">
                        <h2 className="text-lg mb-4">What was your final recommendation to the customer?</h2>
                        <div className="flex flex-row">
                          <ToggleButtonGroup
                            name="finalRecomendation"
                            items={proceedingWithRepairs ? FINAL_RECOMENDATIONS.slice(0, 2) : FINAL_RECOMENDATIONS}
                            activeBackground="#f04e23"
                            background="#FFF2"
                            itemsClassName="!text-[11px]"
                          />
                        </div>
                      </div>
                      {WITH_ESTIMATE_TIMELINE.includes(values["finalRecomendation"]) && (
                        <div className="flex flex-col p-4 border-[1px] rounded-lg mb-4">
                          <h2 className="text-lg mb-4">What is your Estimated Repair Timeline?</h2>
                          <DateInput name="estimatedTimeline" label="Date" />
                          <TextField
                            name="notes"
                            rows={2}
                            placeholder="Notes"
                            inputProps={{
                              className: "content-center text-center",
                            }}
                            multiline
                          />
                        </div>
                      )}
                      {values["finalRecomendation"] && !WITH_ESTIMATE_TIMELINE.includes(values["finalRecomendation"]) && (
                        <div className="flex flex-col p-4 border-[1px] rounded-lg mb-4">
                          <h2 className="text-lg mb-4"> Which services did you recommend?</h2>
                          <CheckboxesField
                            name="recommendedServices"
                            options={SERVICES}
                            values={values["recommendedServices"]}
                            onChange={(e) => {
                              const newValues = [...(values["recommendedServices"] ?? [])];
                              const actualValueIndex = newValues.findIndex((item) => item === e.target.value);
                              if (actualValueIndex >= 0) {
                                newValues.splice(actualValueIndex, 1);
                              } else {
                                newValues.push(e.target.value);
                              }
                              setFieldValue("recommendedServices", newValues);
                            }}
                          />
                          <TextField
                            name="notes"
                            rows={2}
                            placeholder="Notes"
                            inputProps={{
                              className: "content-center text-center",
                            }}
                            multiline
                          />
                        </div>
                      )}
                    </>
                  )}
                  <SubmitButton isSubmitting={isSubmitting} isValid={isValid} label={"Submit"} />
                </Box>
              </Form>
            </Paper>
          </FullScreenDialog>
        );
      }}
    </Formik>
  );
};
