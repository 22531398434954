import { carData } from "./carData";
import { groupBy, mapObjIndexed, prop, pipe } from "ramda";

// @ts-ignore
export const carDataByYear = pipe(
  // @ts-ignore
  groupBy(prop("year")),
  // @ts-ignore
  mapObjIndexed(groupBy(prop("make"))),
  mapObjIndexed(
    mapObjIndexed((models) => {
      // @ts-ignore
      const m = models[0]["models"].split(",");
      const modelObj = m.map((model) => ({ [model]: {} }));
      const modelsRes = Object.assign({}, ...modelObj);
      return modelsRes;
    })
  )
  // @ts-ignore
)(carData);

/* Old Format
export const carDataByYear = {
  "1990": {
    Acura: { Integra: {}, Legend: {} },
    "Alfa Romeo": { Spider: {} },
    "Aston Martin": { Virage: {} },
    Audi: {
      "80": {},
      "90": {},
      "100": {},
      "200": {},
      "100 Quattro": {},
      "200 Quattro": {},
      "80 Quattro": {},
      "90 Quattro": {},
      "Coupe Quattro": {},
      "V8 Quattro": {},
    },
*/
