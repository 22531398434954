import * as React from "react";
import { Form, Formik } from "formik";
import { LocationTextField } from "./fields/LocationTextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { FilesListItem } from "../ListItems/FilesListItem";
import { Box, List } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import { BottomSubmitButtonRow } from "../Buttons/BottomSubmitButtonRow";
import { VehicleInfoFormSection } from "./VehicleInfoFormSection";
import { VehicleInfoSymptomsFormSection } from "./VehicleInfoSymptomsFormSection";
import { TextField } from "./fields/TextField";
import { ListItemWithSubList } from "../ListItems/ListItemWithSubList";
import { pathOr, prop } from "ramda";
import { Contact } from "../../generated/nest-graphql";
import * as Yup from "yup";

export const contactDetailValidationSchema = Yup.object().shape({
  email: Yup.string().optional().email("Must be in email format"),
});

export type ContactDetailsFormValues = {
  zip: string;
  address: string;
  email: string;
  make?: string;
  model?: string;
  year?: string;
  licensePlate?: string;
  vin?: string;
  odometer?: string;
  frontPadLife?: string;
  rearPadLife?: string;
  extraInfo?: string;
  symptoms?: string;
  frontBrakeSymptoms?: string[];
  rearBrakeSymptoms?: string[];
  additionalNotes?: string;
  customerExpectation?: string;
};

export const ContactDetailsFormInternals: React.FC<{
  contactId: string;
  values: ContactDetailsFormValues;
  fileNames: string[];
  setFieldValue: any;
  isSubmitting: boolean;
  isValid: boolean;
  contact: Contact;
}> = ({ contactId, contact, isSubmitting, setFieldValue, values, isValid, fileNames }) => {
  return (
    <Form className={"py-4"}>
      <Box paddingX={2}>
        <TextField name={"email"} label={"Email"} />
      </Box>
      <ListItemWithSubList divider={false} title={"Email"} secondaries={[prop("email", contact)]} />
      <ListItemWithSubList divider={false} title={"Market"} secondaries={[pathOr("", ["lead", "market"], contact)]} />
      <ListItemWithSubList
        divider={false}
        title={"Hear About Us"}
        secondaries={[pathOr("", ["lead", "hearAboutUs"], contact)]}
      />
      <ListItemWithSubList divider={false} title={"Source"} secondaries={[pathOr("", ["lead", "source"], contact)]} />
      <ListItemWithSubList
        divider={true}
        title={"Customer Expectation for Repairs"}
        secondaries={[pathOr("", ["lead", "vehicleInfo", "customerExpectation"], contact)]}
      />
      <Grid className={"p-4 gap-4"} container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">
            Vehicle Information
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <LocationTextField name={"address"} label={"Address"} />
        </Grid>
        <Grid item xs={12}>
          <TextField name={"zip"} label="Zip Code" inputMode={"numeric"} />
        </Grid>
        <VehicleInfoFormSection parentSetFieldValue={setFieldValue} year={values.year} make={values.make} />
        <VehicleInfoSymptomsFormSection />
      </Grid>
      <List>
        <ListItem divider />
        <FilesListItem contactId={contactId} numFiles={fileNames.length} />
      </List>
      <BottomSubmitButtonRow label={"Update Contact"} isSubmitting={isSubmitting} isValid={isValid} />
    </Form>
  );
};

export const ContactDetailsForm: React.FC<{
  initialValues: ContactDetailsFormValues;
  onSubmit: any;
  contactId: string;
  fileNames: string[];
  contact: Contact;
}> = ({ initialValues, contact, onSubmit, contactId, fileNames }) => {
  return (
    <Formik<ContactDetailsFormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={contactDetailValidationSchema}
    >
      {({ values, setFieldValue, isSubmitting, isValid }) => {
        return (
          <ContactDetailsFormInternals
            contactId={contactId}
            contact={contact}
            setFieldValue={setFieldValue}
            values={values}
            isValid={isValid}
            fileNames={fileNames}
            isSubmitting={isSubmitting}
          />
        );
      }}
    </Formik>
  );
};
