import { useState } from "react";
import { pipe } from "ramda";
import { UpdateInvoiceDetailsFormValues } from "../components/Forms/UpdateInvoiceDetailsForm";
import { createInvoiceDetailsSpec } from "../components/Invoices/invoiceDetailsSpec";
import { cleanObject } from "../lib/functions";
import { CreateInvoiceInput, Mutation, MutationCreateInvoiceArgs, Query, QueryGetJobArgs } from "../generated/nest-graphql";
import { useMutation } from "@apollo/client";
import { CREATE_INVOICE } from "../graphql/mutations/createInvoice";
import { useDispatch } from "../contexts/snackbar-context";
import { useHistory } from "react-router-dom";
import { showSuccessAlert } from "../actions";
import { GET_JOB } from "../graphql/queries/getJob";

type result = {createJobInvoice: (jobId: string, values: UpdateInvoiceDetailsFormValues) => Promise<void>};
export const useInvoice = (): result => {
    const [createInvoice] = useMutation<Mutation, MutationCreateInvoiceArgs>(CREATE_INVOICE);
    const dispatch = useDispatch();
    const history = useHistory();
    const createJobInvoice = async (jobId: string, values: UpdateInvoiceDetailsFormValues) => {
        const input = pipe(
          createInvoiceDetailsSpec,
          cleanObject
        )({ ...values, services: values.services.filter((service) => service?.inInvoice) });
        const invoiceInput: CreateInvoiceInput = input as CreateInvoiceInput;
        if(invoiceInput.preJobCheckListV2){
            delete invoiceInput.preJobCheckListV2['licensePlate'];
            delete invoiceInput.preJobCheckListV2['licensePlateState'];
        }
        
        const result = await createInvoice({
          variables: {
            // @ts-ignore
            createInvoiceInput: invoiceInput,
          },
          update: (cache, { data: { createInvoice } }) => {
            const { getJob } = cache.readQuery<Query, QueryGetJobArgs>({
              variables: {
                id: jobId,
              },
              query: GET_JOB,
            });
            const objectToPlace = {
              getJob: {
                ...getJob,
                invoice: createInvoice,
              },
            };
            cache.writeQuery({
              query: GET_JOB,
              variables: {
                id: jobId,
              },
              data: objectToPlace,
            });
          },
        });
        const id = result.data.createInvoice.id;
        showSuccessAlert(dispatch, "Success");
        await history.push(`/invoices/${id}`);
      };

    return {
        createJobInvoice,
    };
}