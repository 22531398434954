import * as React from "react";
import { useField } from "formik";
import { ListItem } from "@material-ui/core";
import { TextField } from "../Forms/fields/TextField";
import { includes } from "ramda";
import { ThumbUp, ThumbDown, ThumbsUpDown, Error, ErrorOutline } from "@material-ui/icons";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { red, green, yellow, grey } from "@material-ui/core/colors";
import { partConditionOptions, dashboardLightsOptions } from "../Forms/fields/PartConditionOptions";
import { ToggleButtonGroup } from "../Forms/fields/ToogleButtonGroup";

const useStyles = makeStyles({
  root: {
    marginBottom: 15,
    border: "2px solid rgb(212, 212, 212)",
    borderRadius: 20,
  },
  up: {
    backgroundColor: green[500],
    "&:focus": {
      backgroundColor: green[500],
    },
    "&:hover": {
      backgroundColor: green[500],
    },
  },
  down: {
    backgroundColor: red[500],
    "&:focus": {
      backgroundColor: red[500],
    },
    "&:hover": {
      backgroundColor: red[500],
    },
  },
  upDown: {
    backgroundColor: yellow[500],
    "&:focus": {
      backgroundColor: yellow[500],
    },
    "&:hover": {
      backgroundColor: yellow[500],
    },
  },
  na: {
    backgroundColor: grey[500],
    "&:focus": {
      backgroundColor: grey[500],
    },
    "&:hover": {
      backgroundColor: grey[500],
    },
  },
  addititionalInfo: {
    paddingTop: 1,
  },
});

const needInfoConditions = [
  "Yellow (Has wear, not in need of immediate repair)",
  "Red (Needs immediate repair)",
  "Warning lights on",
];

export const PartConditionListItem: React.FC<{
  name: string;
  label: string;
}> = ({ name, label }) => {
  return (
    <div className="flex flex-col p-4 border-[1px] rounded-lg mb-4">
      <h2 className="text-lg mb-4">{label}</h2>
      <div className="flex flex-row">
        <ToggleButtonGroup
          name={name}
          items={[
            {
              label: <ThumbUp />,
              value: "green",
              background: green[200],
              activeBackground: green[500],
            },
            {
              label: <ThumbDown />,
              value: "red",
              background: red[300],
              activeBackground: red[500],
            },
          ]}
          activeBackground="#f04e23"
          background="#FFF2"
        />
      </div>
    </div>
  );
};
