import * as React from "react";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { Dialog } from "../Dialog";
import { Mutation } from "../../generated/nest-graphql";
import { useMutation } from "@apollo/client";
import { useDispatch } from "../../contexts/snackbar-context";
import { showErrorAlert, showSuccessAlert } from "../../actions";
import * as Sentry from "@sentry/browser";
import { SEND_BUY_NOW_PAY_LATER_EVENT } from "../../graphql/mutations/sendBuyNowPayLaterEvent";

export const BuyNowPayLaterDialog: React.FC<{
  invoiceId: string;
  open: boolean;
  onClose: () => void;
  buyNowPayLaterChosen: () => void;
}> = ({ invoiceId, open, onClose, buyNowPayLaterChosen }) => {
  const dispatch = useDispatch();
  const [sendBuyNowPayLaterEvent, { loading }] = useMutation<Mutation>(SEND_BUY_NOW_PAY_LATER_EVENT);
  const handleBuyNowPayLater = async () => {
    try {
      await sendBuyNowPayLaterEvent({
        variables: { invoiceId },
      });
      buyNowPayLaterChosen();
      showSuccessAlert(dispatch, "Pay Later link sent");
      onClose();
    } catch (e) {
      showErrorAlert(dispatch, "Failed Sending Pay Later link");
      onClose();
      Sentry.captureException(e, {
        extra: { invoiceId },
      });
    }
  };

  return (
    <Dialog title={"Text customer a “Buy Now Pay Later” checkout link"} open={open} onClose={onClose}>
      <div className="mb-8">
        <Typography gutterBottom>
          By hitting confirm, your customer will be texted a link to a payment page where they can choose a Buy Now Pay
          Later method. Your job will be closed automatically upon payment.
        </Typography>
      </div>
      <div className="flex justify-between mb-4">
        <Button onClick={onClose} size={"large"} color={"default"} variant={"outlined"}>
          Cancel
        </Button>
        <Button
          disabled={loading}
          onClick={handleBuyNowPayLater}
          size={"large"}
          color={"primary"}
          variant={"contained"}
        >
          {loading ? <CircularProgress /> : "Send Link"}
        </Button>
      </div>
    </Dialog>
  );
};
