import * as Yup from "yup";
import { WITH_ESTIMATE_TIMELINE } from "./constants";

export const checkListSchema = (repairIsHappening?: boolean) =>
  Yup.object().shape({
    needOutstandingRepair: repairIsHappening ? Yup.boolean().required("required") : Yup.boolean().optional(),
    finalRecomendation: Yup.string().when("needOutstandingRepair", {
      is: (needOutstandingRepair) => needOutstandingRepair && !repairIsHappening,
      then: Yup.string().required("Required"),
      otherwise: Yup.string().nullable().optional(),
    }),
    recommendedServices: Yup.array(Yup.string()).nullable().optional(),
    notes: Yup.string().nullable().optional(),
    estimatedTimeline: Yup.date().when("finalRecomendation", {
      is: (finalRecomendation) => WITH_ESTIMATE_TIMELINE.includes(finalRecomendation),
      then: Yup.date().required("Required"),
      otherwise: Yup.date().nullable().optional(),
    }),
  });
