import React, { useState } from "react";
import { FormItems } from "./forms";
import { Accordion, AccordionDetails, AccordionSummary, IconButton } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { FieldGenerator } from "./FieldGenerator";

type Props = {
  form: FormItems;
};

export const FormGenerator = ({ form }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <>
      {form.map((item, idx) => {
        if (item.itemType === "section") {
          return (
            <div className="grid grid-cols-2 gap-4 p-4 border-[1px] rounded-lg mb-4" key={idx}>
              <div className="flex flex-row items-center mb-4 col-span-full">
                {item.title && <h2 className="text-lg">{item.title}</h2>}
                {item.required && (
                  <>
                    <div className="ml-1">Required</div>
                    <div className="ml-4 text-[#D50000]">*</div>
                  </>
                )}
              </div>
              {item.subTitle && <h3 className="col-span-full">{item.subTitle}</h3>}
              {item.children?.length && <FormGenerator form={item.children as FormItems} />}
              {item.description && <div className="col-span-full">{item.description}</div>}
            </div>
          );
        } else if (item.itemType === "drawer") {
          return (
            <Accordion
              key={idx}
              style={{
                borderRadius: 12,
                border: "1px solid #E5E5E5",
                marginBottom: 16,
              }}
              expanded={isExpanded}
            >
              <AccordionSummary
                className="flex items-center justify-center"
                onClick={() => setIsExpanded((expanded) => !expanded)}
              >
                <div className="flex items-center">{item.title && <p>{item.title}</p>}</div>

                <div>
                  <IconButton>{isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="grid flex-col w-full">
                  {item.children?.length && <FormGenerator form={item.children as FormItems} />}
                </div>
              </AccordionDetails>
            </Accordion>
          );
        } else if (item.itemType === "field") {
          return <FieldGenerator key={idx} field={item} />;
        }
      })}
    </>
  );
};
