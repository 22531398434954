import React from "react";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { AddPaymentsDialog } from "../Payments/AddPaymentsDialog";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { InvoiceActionButton } from "./InvoiceActionButton";
import { useToggle } from "../../hooks/useToggle";
import { CheckCircle, PlaylistAddCheckOutlined } from "@material-ui/icons";
import { CompleteJobDialog } from "../Payments/CompleteJobDialog";
import { Contact, Query, QueryGetJobArgs } from "../../generated/nest-graphql";
import { useQuery } from "@apollo/client";
import { GET_JOB } from "../../graphql/queries/getJob";
import { PostJobCheckListDialog } from "../PostJobChecklist/PostJobCheckListDialog";
import { postJobCheckListInitialValues } from "../Jobs/jobDetailsSpec";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        marginLeft: theme.spacing(1.0),
      },
    },
  })
);

export const InvoiceStatusSection: React.FC<{
  status: string;
  invoiceId: string;
  contactId: string;
  balanceDue: string;
  amount: string;
  refetch: any;
  laborCost: string;
  partsCost: string;
  payer: string;
  partsTax: string;
  laborTax: string;
  totalTax: string;
  subTotal: string;
  jobId: string;
  checkListsCreated: boolean;
  contact: Contact;
  amountPaid: string;
  proceedingWithRepairs?: boolean;
}> = ({
  laborCost,
  partsCost,
  partsTax,
  laborTax,
  subTotal,
  payer,
  totalTax,
  status,
  invoiceId,
  contactId,
  balanceDue,
  amount,
  refetch,
  jobId,
  checkListsCreated,
  contact,
  amountPaid,
  proceedingWithRepairs,
}) => {
    const classes = useStyles();
    const [addPaymentDialogStatus, , toggleAddPayment] = useToggle();
    const [completeJobDialogStatus, , toggleCompleteJob] = useToggle();
    const [jobChecklistDialogStatus, , toggleJobChecklist] = useToggle();
    const { data, refetch: refetchJob, loading } = useQuery<Query, QueryGetJobArgs>(GET_JOB, {
      variables: {
        id: jobId,
      },
      fetchPolicy: "cache-first",
      returnPartialData: true,
    });
    const job = data?.getJob;
    const jobType = job?.type;
    const shouldUseInspectionV2 = jobType === "Vioc Inspection";

    const openInspection = () => {
      toggleAddPayment();
      toggleJobChecklist();
    };

    if(loading){
      return null;
    }

    return (
      <Paper square>
        <Box display={"flex"}>
          <Box className="py-2 px-2 w-1/2 flex flex-col justify-center">
            <Chip color={"primary"} label={status} />
          </Box>
          <Box className={classes.root + " flex-1 flex flex-row justify-end"}>
            {status === "Paid" ? <InvoiceActionButton invoiceId={invoiceId} invoiceStatus={status} /> : null}

            <Button
              className="w-1/3 bg-green-400"
              style={{ background: "#3E6" }}
              variant={"contained"}
              color={"inherit"}
              onClick={toggleJobChecklist}
            >
              <div>
                <PlaylistAddCheckOutlined />
                <Typography display={"block"} variant={"caption"}>
                  Complete Post Job Checklist
                </Typography>
              </div>
            </Button>
            {Number(balanceDue) === 0 ? (
              <>
                <Button
                  className="w-1/3"
                  variant={"contained"}
                  color={"secondary"}
                  onClick={toggleCompleteJob}
                  disabled={status === "Paid" || !job.postJobChecklist}
                >
                  <div>
                    <CheckCircle />
                    <Typography display={"block"} variant={"caption"}>
                      Complete Job & Invoice
                    </Typography>
                  </div>
                </Button>
              </>
            ) : (
              <Button
                className="w-1/3"
                variant={"contained"}
                color={"secondary"}
                onClick={toggleAddPayment}
                disabled={status === "Paid"}
              >
                <div>
                  <MonetizationOnIcon />
                  <Typography display={"block"} variant={"caption"}>
                    Get Paid
                  </Typography>
                </div>
              </Button>
            )}
          </Box>
        </Box>
        {job && (
          <CompleteJobDialog
            open={completeJobDialogStatus}
            onClose={toggleCompleteJob}
            invoiceId={invoiceId}
            contactId={contactId}
            refetch={refetch}
            job={job}
          />
        )}
        <AddPaymentsDialog
          open={addPaymentDialogStatus}
          onClose={toggleAddPayment}
          invoiceId={invoiceId}
          contactId={contactId}
          payer={payer}
          amount={amount}
          balanceDue={balanceDue}
          laborCost={laborCost}
          partsCost={partsCost}
          partsTax={partsTax}
          laborTax={laborTax}
          subTotal={subTotal}
          totalTax={totalTax}
          refetch={refetch}
          jobId={jobId}
          checkListsCreated={
            shouldUseInspectionV2 ? true : (checkListsCreated || !!job?.preJobCheckListV2) && !!job?.postJobChecklist
          }
          contact={contact}
          amountPaid={amountPaid}
          status={status}
          openInspection={openInspection}
        />
        <PostJobCheckListDialog
          open={jobChecklistDialogStatus}
          onClose={toggleJobChecklist}
          isVioc={job?.type === "Vioc Inspection"}
          parentSubmit={refetchJob}
          jobId={jobId}
          initialValues={postJobCheckListInitialValues(job)}
          proceedingWithRepairs={proceedingWithRepairs}
        />
      </Paper>
    );
  };
