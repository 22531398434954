import * as React from "react";
import { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { getMakesByYear, getModelsByYearAndMake, getYears } from "../../lib/quoteFormUtil";
import { Select } from "./fields/Select";
import { TextField } from "./fields/TextField";
import { AutoCompleteSelectField } from "./fields/AutoCompleteSelectField";
import { CheckBoxField } from "../CheckBoxField";

const padLifeOptions = [
  "1mm",
  "1.5mm",
  "2mm",
  "2.5mm",
  "3mm",
  "3.5mm",
  "4mm",
  "4.5mm",
  "5mm",
  "5.5mm",
  "6mm",
  "6.5mm",
  "7mm",
  "7.5mm",
  "8mm",
];

const PARKING_BRAKE_OPTIONS = [
  'Manual',
  'Electric',
]

export const VehicleInfoFormSection: React.FC<{
  parentSetFieldValue: any;
  year: any;
  make: any;
}> = ({ parentSetFieldValue, year, make }) => {
  const years = getYears();
  const [modelOptions, setModelOptions] = useState([""]);
  const [makeOptions, setMakeOptions] = useState([""]);

  useEffect(() => {
    if (year) {
      setMakeOptions(getMakesByYear(year));
    }
    if (year && make) {
      setModelOptions(getModelsByYearAndMake(year, make));
    }
  }, [make, year]);

  return (
    <Grid container item spacing={2}>
      <Grid item xs={12}>
        <TextField name={"name"} label="Name" disabled />
      </Grid>
      <Grid item xs={6}>
        <AutoCompleteSelectField
          name={"year"}
          options={years}
          label={"Year"}
          postOnChange={(year) => {
            parentSetFieldValue("make", "");
            parentSetFieldValue("model", "");
            if (year) {
              setMakeOptions(getMakesByYear(year));
            }
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <AutoCompleteSelectField
          name={"make"}
          options={makeOptions}
          label={"Make"}
          postOnChange={(vehicleMake) => {
            parentSetFieldValue("model", "");
            if (vehicleMake && year) {
              setModelOptions(getModelsByYearAndMake(year, vehicleMake));
            }
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <AutoCompleteSelectField name={"model"} options={modelOptions} label={"Model"} />
      </Grid>
      <Grid item xs={6}>
        <TextField name={"subModel"} label="Sub Model" />
      </Grid>
      <Grid item xs={6}>
        <TextField name={"licensePlate"} label="License Plate" />
      </Grid>
      <Grid item xs={6}>
        <TextField name={"licensePlateState"} label="License Plate State" />
      </Grid>
      <Grid item xs={6}>
        <TextField name={"vin"} label="Vin" />
      </Grid>
      <Grid item xs={6}>
        <TextField name={"odometer"} label="Odometer" inputMode={"numeric"} />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name={"wheelTorque"}
          label={"Wheel Torque"}
          type="number"/>
      </Grid>
      <Grid item xs={6}>

      <Select name={"parkingBrake"} options={PARKING_BRAKE_OPTIONS} fullWidth={true} label="Parking Brake Type" />
      </Grid>
      <Grid item xs={12}>
        <TextField name={"notes"} label="Vehicle Info" multiline={true} rows={3} />
      </Grid>
      <Grid item xs={6}>
        <TextField
          className="px-0"
          name={"frontRotorsDiscardThicknessInches"}
          label={"Front Rotors Discard Thickness"}
          type="number"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          className="px-0"
          name={"rearRotorsDiscardThicknessInches"}
          label={"Front Rotors Discard Thickness"}
          type="number"
        />
      </Grid>
      <Grid item xs={6}>
        <CheckBoxField name={"inactive"} label={"Inactive"} />
      </Grid>
      <Grid item xs={6}>
        <CheckBoxField name={"hasDrumBrakes"} label={"has Drum Brakes"} />
      </Grid>


    </Grid>
  );
};
