import React, { ReactNode } from "react";
import {
  default as CoreToggleButtonGroup,
  ToggleButtonGroupProps as CoreToggleButtonGroupProps,
} from "@material-ui/lab/ToggleButtonGroup";
import { default as CoreToggleButton, ToggleButtonProps as CoreToggleButtonProps } from "@material-ui/lab/ToggleButton";
import { useField } from "formik";
import { isEmpty } from "ramda";
import { FormControl, FormHelperText } from "@material-ui/core";

export type gruopItemProps = {
  label: ReactNode;
  value: any;
  background?: string;
  activeBackground?: string;
  inputProps?: CoreToggleButtonProps;
};

export const ToggleButtonGroup: React.FC<
  CoreToggleButtonGroupProps & {
    items: gruopItemProps[];
    name: string;
    label?: ReactNode;
    readOnly?: boolean;
    variant?: "outlined" | "filled" | "standard";
    inputMode?: "none" | "text" | "tel" | "url" | "email" | "numeric" | "decimal" | "search";
    disabled?: boolean;
    errorText?: string;
    textAlign?: "start" | "end" | "left" | "right" | "center" | "justify" | "match-parent";
    fullWidth?: boolean;
    background?: string;
    activeBackground?: string;
    itemsClassName?: string;
  }
> = ({
  name,
  label,
  inputMode = "text",
  variant = "outlined",
  readOnly = false,
  disabled = false,
  errorText = "This is a required field.",
  textAlign = "left",
  items,
  fullWidth,
  background,
  activeBackground,
  itemsClassName,
  ...props
}) => {
  const [field, meta, { setValue }] = useField(name);
  const { error } = meta;
  const { value, onChange, ...rest } = field;
  return (
    <FormControl variant="outlined" fullWidth={fullWidth} error={!isEmpty(error)}>
      <CoreToggleButtonGroup value={value ? value : ""} {...rest} {...props}>
        {items.map((item, idx) => {
          const active = item.value === value;
          return (
            <CoreToggleButton
              {...item?.inputProps}
              onClick={() => setValue(item.value)}
              style={{
                ...item?.inputProps?.style,
                backgroundColor: active ? item.activeBackground ?? activeBackground : item.background ?? background,
                color: "white",
              }}
              key={idx}
              disabled={disabled || item.inputProps?.disabled}
              value={item.value}
              className={itemsClassName}
            >
              {item?.label}
            </CoreToggleButton>
          );
        })}
      </CoreToggleButtonGroup>
      {!isEmpty(error) && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
