import gql from "graphql-tag";
import { ejiPriceInfoFields } from "./calculatePossibleEjiPriceInfo";
import { partDetailFields } from "./getPartCatalogue";
import { viocStoreFields } from "./getAllViocStores";

export const preJobCheckListItemField = `{
            partCondition
            additionalInfo
        } `;

export const brakePadsListItemField = `{
    padThickness {
        thickness
        unavailable
    }
    condition
}`;

export const oilLifeListItemField = `{
    estimatedNextOilChangeTimeline
    nextOilChangeMileage {
        mileage
        unavailable
    }
    nextOilChangeDate {
        date
        unavailable
    }
    lastOilChangeDate {
        date
        unavailable
    }
}`;

export const tireTreadListItemField = `{
    frontRight {
        depth
        unavailable
    }
    frontLeft {
        depth
        unavailable
    }
    rearRight {
        depth
        unavailable
    }
    rearLeft {
        depth
        unavailable
    }
}`;

export const preJobCheckListField = `
    preJobCheckList {
        frontPads ${preJobCheckListItemField}
        frontPadsV2 ${brakePadsListItemField}
        frontRotors ${preJobCheckListItemField}
        rearPads ${preJobCheckListItemField}
        rearPadsV2 ${brakePadsListItemField}
        rearRotors ${preJobCheckListItemField}
        brakeFluid ${preJobCheckListItemField}
        brakeShoes ${preJobCheckListItemField}
        brakeDrums ${preJobCheckListItemField}
        calipers ${preJobCheckListItemField}
        shocksStruts ${preJobCheckListItemField}
        controlArms ${preJobCheckListItemField}
        wheelHubs ${preJobCheckListItemField}
        studs ${preJobCheckListItemField}
        lugnuts ${preJobCheckListItemField}
        sparkPlugs ${preJobCheckListItemField}
        carBattery ${preJobCheckListItemField}
        alternator ${preJobCheckListItemField}
        engineAirFilter ${preJobCheckListItemField}
        cabinAirFilter ${preJobCheckListItemField}
        otherBrakeParts ${preJobCheckListItemField}
        dashboardLights ${preJobCheckListItemField}
        oilLife ${oilLifeListItemField}
        tireTread ${tireTreadListItemField}
    }
`;

export const checkListV2BrakeFluidField = `{
  ppm
  colorGrade
  level
}`;

export const checkListV2PadsField = `{
  innerPadThickness
  outerPadThickness
  padsThickness
  condition
}`;

export const checkListV2RotorsField = `{
  thicknessInches
  condition
}`;

export const checkListV2ShoesField = `{
  colorGrade
  condition
}`;

export const checkListV2DrumsField = `{
  colorGrade
  condition
}`;

export const checkListV2InspectedServiceItemField = `{
  included
  colorGrade
  notes
}`;

export const checkListV2InspectedServicesField = `{
  frontLeftCaliper ${checkListV2InspectedServiceItemField}
  frontRightCaliper ${checkListV2InspectedServiceItemField}
  rearLeftCaliper ${checkListV2InspectedServiceItemField}
  rearRightCaliper ${checkListV2InspectedServiceItemField}
  frontLeftHubAssemblyBearing ${checkListV2InspectedServiceItemField}
  frontRightHubAssemblyBearing ${checkListV2InspectedServiceItemField}
  rearLeftHubAssemblyBearing ${checkListV2InspectedServiceItemField}
  rearRightHubAssemblyBearing ${checkListV2InspectedServiceItemField}
}`;

export const preJobCheckListV2Field = `
 preJobCheckListV2 {
  odometer
  brakeFluid ${checkListV2BrakeFluidField}
  frontPads ${checkListV2PadsField}
  frontRotors ${checkListV2RotorsField}
  rearPads ${checkListV2PadsField}
  rearRotors ${checkListV2RotorsField}
  usesRearShoesAndDrums
  rearShoes ${checkListV2ShoesField}
  rearDrums ${checkListV2DrumsField}
  privateNotes
  inspectedServices ${checkListV2InspectedServicesField}
  withoutCCA
  coldCrankingAmps
  withoutBatteryHealth
  batteryHealth
}`;

export const ejiItemFields = `
    id
    serviceProductId
    productSelection {
        name
        part {
            ${partDetailFields}
        }
    }
    type
    units
    overriddenCustomerPrice
    customerPrice
    vendorUnitCost
    behavior
    category
    notes
    orderItem {
        partsStore {
            id
            name
            vendor
        }
        isOrdered
        partNumber
    }
    marketVendorUnitCostMultiplierUsed
    marketLaborRateUsed
`;

export const partsCalcsFields = `
    marketVendorUnitCostMultiplierUsed
    overriddenCustomerPrice
    vendorCost
    totalPrice
`;

export const ejiServiceFields = `
    id
    service
    name
    inEstimate
    customerPrice
    technicianPay
    partsCalcs {
        ${partsCalcsFields}
    }
    items {
        ${ejiItemFields}
    }
`;

export const jobVehicleInfoFields = `
  frontPadLife
  licensePlate
  make
  model
  odometer
  frontRotorsDiscardThicknessInches
  rearRotorsDiscardThicknessInches
  rearPadLife
  vin
  year
  extraInfo
  wheelTorque
  symptoms
  frontBrakeSymptoms
  rearBrakeSymptoms
  customerExpectation
  additionalNotes
  CCA
`;

export const jobDetailsFields = `
        locationType
        inspectionReportSentAt
        invoice {
            id
        }
        vehicleInfo {
            ${jobVehicleInfoFields}
        }
        vehicleId
        appointment {
            id
            isFixed
            allDay
            endDate
            startDate
            subject
            technician {
                id
                firstName
                lastName
            }
        }
        contact {
            id
            phoneNumber
            firstName
            lastName
            email
            stripeCustomer
            stripePaymentMethods
        }
        feedback {
            rating
            doneBetter
            additionalComments
        }
        partsOrderingIssuesSurveys {
            partsCorrect
            partsStores
            partsIssues
            additionalDetails 
            completed
            submittedBy
        }
        description
        id
        jobNumber
        serviceCatalogueUsed
        market
        items {
            product
            partsStore {
                id
                name
                vendor
                address
            }
            name
            amount
            description
            isInEstimate
            partNumber
            laborCost
            partsCost
            createdAt
            updatedAt
        }
        partsStore {
            id
            name
            vendor
        }
        jobName
        type
        serviceCallReason
        serviceCallReasonExtraInfo
        warrantyCheckReason
        warrantyCheckReasonExtraInfo
        warrantyCheckTechReason
        warrantyCheckTechReasonExtraInfo
        contact {
            id
            firstName
            lastName
            fullName
            files {
                fileName
            }
            stripeCustomer
            stripePaymentMethods
            fleet {
                name
            }
        }
        serviceLocation
        partsInfo {
            partsLocation
            partsOrderNumber
            partsOrdered
            partsNotes
        }
        ${preJobCheckListField}
        ${preJobCheckListV2Field}
        estimate {
            id
        }
        privateNotes
        serviceLocationNotes
        taxable
        createdAt
        updatedAt
        status
        serviceCatalogueUsed
        services {
            ${ejiServiceFields}
        }
        priceInfo {
            ${ejiPriceInfoFields}
        }
        viocStore {
          ${viocStoreFields}
        }
        postJobChecklist {
            finalRecomendation
            recommendedServices
            notes
            estimatedTimeline
            needOutstandingRepair
            wheelStuds
            lugnuts
            dashboardLights
            testDriveExpirience
            otherConcerns
        }
        `;

export const GET_JOB = gql`query getJob($id: ID!) {
    getJob(id: $id) {
        ${jobDetailsFields}
    }
}`;

export const GET_JOB_FOR_INSPECTION = gql`query getJob($id: ID!) {
  getJob(id: $id) {
    id
    type
    contact {
      id
      phoneNumber
    }
    inspectionReportSentAt
    postJobChecklist {
        finalRecomendation
        recommendedServices
        notes
        estimatedTimeline
        needOutstandingRepair
        wheelStuds
        lugnuts
        dashboardLights
        testDriveExpirience
        otherConcerns
    }
    vehicleInfo {
        ${jobVehicleInfoFields}
     }
    vehicleId
  }
}`;
