import * as React from "react";
import { AppBar } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Typography from "@material-ui/core/Typography";
import { InvoiceStatusSection } from "./InvoiceStatusSection";
import { useHistory } from "react-router-dom";
import { Contact } from "../../generated/nest-graphql";

export const InvoiceDetailsAppBar: React.FC<{
  invoiceNumber: string;
  status: string;
  invoiceId: string;
  contactId: string;
  payer: string;
  amount: string;
  balanceDue: string;
  laborCost: string;
  partsCost: string;
  partsTax: string;
  laborTax: string;
  totalTax: string;
  subTotal: string;
  refetch: any;
  jobId: string;
  checkListsCreated: boolean;
  contact: Contact;
  amountPaid: string;
  proceedingWithRepairs?: boolean;
}> = ({
  invoiceNumber,
  status,
  invoiceId,
  payer,
  contactId,
  amount,
  balanceDue,
  refetch,
  laborCost,
  partsCost,
  partsTax,
  laborTax,
  subTotal,
  totalTax,
  jobId,
  checkListsCreated,
  contact,
  amountPaid,
  proceedingWithRepairs,
}) => {
  const history = useHistory();
  return (
    <AppBar position={"sticky"}>
      <Toolbar>
        <IconButton onClick={history.goBack}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography variant="h6">{`Invoice: ${invoiceNumber}`}</Typography>
      </Toolbar>
      <InvoiceStatusSection
        laborCost={laborCost}
        payer={payer}
        partsCost={partsCost}
        partsTax={partsTax}
        laborTax={laborTax}
        subTotal={subTotal}
        totalTax={totalTax}
        status={status}
        invoiceId={invoiceId}
        contactId={contactId}
        amount={amount}
        balanceDue={balanceDue}
        refetch={refetch}
        jobId={jobId}
        checkListsCreated={checkListsCreated}
        contact={contact}
        amountPaid={amountPaid}
        proceedingWithRepairs={proceedingWithRepairs}
      />
    </AppBar>
  );
};
