import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages";
import MorePage from "./pages/more";
import ContactSummaryPage from "./pages/contacts/[id]";
import InvoiceDetailsPage from "./pages/invoices/[id]";
import JobsHistoryPage from "./pages/jobs-history/[id]";
import JobSummaryPage from "./pages/jobs/[id]";
import * as Sentry from "@sentry/react";
import PreferencesPage from "./pages/preferences";
import { InspectionProvider } from "./contexts/inspection-context";
import { PartsOrderingIssuesSurveyProvider } from "./contexts/parts-ordering-issues-survey-context";
import { InspectionProviderV2 } from "./contexts/inspection-context-v2";

export const Routes: React.FC<{}> = () => {
  const { user } = useAuth0();
  Sentry.setUser({ username: user.name });
  return (
    <Router>
      <Switch>
        <Route path={"/"} exact component={Home} />
        <Route path={"/more"} exact component={MorePage} />
        <Route path={"/contacts/:id"} component={ContactSummaryPage} />
        <Route path={"/preferences"} exact component={PreferencesPage} />
        <InspectionProvider>
          <InspectionProviderV2>
            <PartsOrderingIssuesSurveyProvider>
              <Route path={"/invoices/:id"} component={InvoiceDetailsPage} />
              <Route path={"/jobs/:id"} component={JobSummaryPage} />
              <Route path={"/jobs-history/:id"} component={JobsHistoryPage} />
            </PartsOrderingIssuesSurveyProvider>
          </InspectionProviderV2>
        </InspectionProvider>
      </Switch>
    </Router>
  );
};
