import gql from "graphql-tag";

export const GET_MY_APPOINTMENTS = gql`
  query getMyAppointments($startRange: Date!, $endRange: Date!, $filter: JSON) {
    getMyAppointments(startRange: $startRange, endRange: $endRange, filter: $filter) {
      id
      allDay
      isFixed
      endDate
      startDate
      subject
      technician {
        id
        firstName
        lastName
        market
        homePartsStore {
          id
          name
          vendor
        }
      }
      job {
        zendeskTags
        id
        status
        jobName
        jobNumber
        serviceCatalogueUsed
        type
        vehicleInfo {
          make
          model
          year
        }
        items {
          name
          partsStore {
            id
            name
            vendor
            address
          }
        }
        partsInfo {
          partsLocation
          partsOrderNumber
          partsOrdered
          partsNotes
        }
        serviceLocation
        serviceLocationNotes
        description
        contact {
          id
          fullName
        }
      }
    }
  }
`;
