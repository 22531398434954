import React from "react";
import { path } from "ramda";
import { useField } from "formik";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { default as CoreSelect, SelectProps as CoreSelectProps } from "@material-ui/core/Select";
import { FormHelperText } from "@material-ui/core";

export const Select: React.FC<
  CoreSelectProps & {
    name: string;
    value?: string | string[];
    required?: boolean;
    options: string[];
    label: string;
    fullWidth?: boolean;
    defaultValue?: string;
    postOnChange?: (val: any, previousVal?: any) => void;
    containerStyle?: React.CSSProperties;
  }
> = ({ label, options, required, name, fullWidth, postOnChange, containerStyle, ...props }) => {
  const [field, meta, helper] = useField(name);

  return (
    <FormControl error={!!meta.error} variant="outlined" fullWidth={fullWidth} style={containerStyle}>
      <InputLabel>{label}</InputLabel>
      <CoreSelect
        label={label}
        required={required}
        {...field}
        {...props}
        onChange={(event) => {
          // @ts-ignore
          const value = path<string>(["target", "value"], event);
          const previousVal = field.value;
          helper.setValue(value);
          postOnChange && postOnChange(value, previousVal);
        }}
      >
        {options.map((val, i) => (
          <MenuItem key={i} value={val}>
            {val}
          </MenuItem>
        ))}
      </CoreSelect>
      {meta?.error ? <FormHelperText>{meta?.error}</FormHelperText> : null}
    </FormControl>
  );
};
