import { Box, Paper } from "@material-ui/core";
import React, { useEffect } from "react";
import { useToggle } from "../../hooks/useToggle";
import { BottomSubmitButtonRow } from "../Buttons/BottomSubmitButtonRow";
import { JobDetailsFormValues } from "../Forms/JobDetailsForm";
import { JobTypeFormSection } from "../Forms/JobTypeFormSection";
import { FullScreenDialog } from "../FullScreenDialog";
import { ListItemWithSubList } from "./ListItemWithSubList";
import { useHistory, useLocation } from "react-router-dom";

export const JobTypeListItem: React.FC<{
  jobType: string;
  parentSubmitForm: any;
  parentSubmitting: boolean;
  isValid: boolean;
  values: JobDetailsFormValues;
}> = ({ jobType, parentSubmitForm, parentSubmitting, isValid, values }) => {
  const [open, setIsOpen, toggleDialog] = useToggle();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const { search, pathname } = location;
    const openModal = (new URLSearchParams(search)).get('openJobTypeModal');
    if (openModal) {
      setIsOpen(true);
      history.replace(pathname);
    }
  }, [location])

  return (
    <>
      <ListItemWithSubList divider={true} title={"Type"} secondaries={[jobType]} button={true} onClick={toggleDialog} />
      <FullScreenDialog title="Job Type" open={open} onClose={toggleDialog}>
        <Paper className={"px-3"}>
          <Box marginBottom={2} paddingTop={4}>
            <JobTypeFormSection values={values} />
          </Box>
          <Box marginBottom={2} paddingTop={4}>
            <BottomSubmitButtonRow
              label={"Update Job Type"}
              isValid={isValid}
              isSubmitting={parentSubmitting}
              handleSubmit={parentSubmitForm}
              pinToBottom={true}
            />
          </Box>
        </Paper>
      </FullScreenDialog>
    </>
  );
};
