import gql from "graphql-tag";

export const GET_FILES = gql`
  query getFiles($contactId: ID!) {
    getFiles(contactId: $contactId) {
      fileName
      signedUrl
      url
      caption
      category
      jobId
      share
      createdAt
      updatedAt
    }
  }
`;
