import * as React from "react";
import { VehicleInfoFormSection } from "../Forms/VehicleInfoFormSection";
import { FullScreenDialog } from "../FullScreenDialog";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { BottomSubmitButtonRow } from "../Buttons/BottomSubmitButtonRow";
import { ListItemWithSubList } from "./ListItemWithSubList";
import { useToggle } from "../../hooks/useToggle";
import { useMutation, useQuery } from "@apollo/client";
import {
  Mutation,
  MutationUpsertVehicleArgs,
  Query,
  QueryGetVehicleArgs,
  VehicleInput,
  VehicleTechnicalInfo,
  VehicleType,
} from "../../generated/nest-graphql";
import { GET_VEHICLE } from "../../graphql/queries/getVehicles";
import { Formik } from "formik";
import { UPSERT_VEHICLE } from "../../graphql/mutations/upsertVehicle";
import { useDispatch } from "../../contexts/snackbar-context";
import { showErrorAlert, showSuccessAlert } from "../../actions";

export type VehicleInfoListItemForm = Omit<
  VehicleType,
  "vehicleTechnicalInfo" | "contactId" | "__typename" | "odometerHistory" | "inactive" | "createdAt" | "updatedAt"
> &
  Omit<VehicleTechnicalInfo, "__typename">;

export const VehicleInfoListItem: React.FC<{
  vehicleId: string;
  contactId: string;
}> = ({ vehicleId, contactId }) => {
  const [vehicleInfoOpen, , toggleVehicleInfo] = useToggle();
  const dispatch = useDispatch();

  const [upsertVehicle] = useMutation<Mutation, MutationUpsertVehicleArgs>(UPSERT_VEHICLE);
  const { data, loading } = useQuery<Query, QueryGetVehicleArgs>(GET_VEHICLE, {
    variables: {
      id: vehicleId,
    },
  });

  const vehicle = loading ? {} : data?.getVehicle;

  const initialValues: VehicleInfoListItemForm = {
    ...vehicle,
    ...vehicle?.vehicleTechnicalInfo,
  } as VehicleInput;

  const onSubmit = async (values: VehicleInfoListItemForm) => {
    try {
      const {
        id,
        make,
        model,
        subModel,
        year,
        vin,
        name,
        licensePlate,
        licensePlateState,
        frontRotorsDiscardThicknessInches,
        rearRotorsDiscardThicknessInches,
        notes,
        odometer,
        wheelTorque,
        hasDrumBrakes,
        parkingBrake,
      } = values;

      const vehicleInputData: VehicleInput = {
        contactId,
        id,
        make,
        model,
        subModel,
        year,
        vin,
        name,
        licensePlate,
        licensePlateState,
        frontRotorsDiscardThicknessInches,
        rearRotorsDiscardThicknessInches,
        notes,
        odometer,
        wheelTorque: String(wheelTorque),
        hasDrumBrakes,
        parkingBrake,
      };

      await upsertVehicle({
        variables: {
          vehicleInputData,
        },
      });

      showSuccessAlert(dispatch, "Vehicle Updated");
    } catch (e) {
      console.error(e);
      showErrorAlert(dispatch, "Error saving vehicle");
    }
  };

  return (
    <Formik<VehicleInfoListItemForm>
      initialValues={initialValues}
      enableReinitialize={true}
      //validationSchema={jobDetailsFormValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, isSubmitting, setFieldValue, values, submitForm, dirty, setValues }) => {
        const { year, make, model } = values;
        const vehicleInfo = [!loading ? `${year} ${make} ${model}` : "Loading..."];
        return (
          <>
            <ListItemWithSubList
              divider
              title={"Vehicle Info"}
              secondaries={vehicleInfo}
              button={true}
              onClick={toggleVehicleInfo}
            />
            <FullScreenDialog title="Vehicle Info" open={vehicleInfoOpen} onClose={toggleVehicleInfo}>
              <Paper className={"px-3"}>
                <Box marginBottom={2} paddingTop={4}>
                  <VehicleInfoFormSection parentSetFieldValue={setFieldValue} year={year} make={make} />
                </Box>
                <Box marginTop={2} paddingBottom={4}>
                  <BottomSubmitButtonRow
                    label={"Update Job Vehicle Info"}
                    isValid={isValid}
                    isSubmitting={isSubmitting}
                    handleSubmit={submitForm}
                    pinToBottom={true}
                  />
                </Box>
              </Paper>
            </FullScreenDialog>
          </>
        );
      }}
    </Formik>
  );
};
