import React from "react";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import { JobActionButton } from "./JobActionButton";
import { GoToInvoiceButton } from "./GoToInvoiceButton";
import { UpdateInvoiceDetailsFormValues } from "../Forms/UpdateInvoiceDetailsForm";
import { equals, isEmpty, union } from "ramda";
import { preJobCheckListInitialState, useInspectionReport } from "../../contexts/inspection-context";
import {
  inspectedServicesInitialState,
  preJobCheckListInitialState as preJobCheckListInitialStateV2,
} from "../../contexts/inspection-context-v2";
import cloneDeep from "lodash.clonedeep";
import { Contact, Query, QueryGetJobArgs } from "../../generated/nest-graphql";
import { useInspectionReportV2 } from "../../contexts/inspection-context-v2";
import { useQuery } from "@apollo/client";
import { GET_JOB } from "../../graphql/queries/getJob";
import { OpenInspectionButtonV2 } from "../PreJobChecklistV2/OpenInspectionButtonV2";
import { postJobCheckListInitialValues } from "./jobDetailsSpec";

// This merge function is designed to prefer non-default checklist values.
// All of Ramda's `merge` functions traverse through objects recursively,
// which is not the desired behavior in this case.
export const mergePreJobChecklistFunc = (currentValues, initialValues) => {
  if (isEmpty(currentValues)) return initialValues;
  if (isEmpty(initialValues)) return currentValues;

  let objectKeys = union(Object.keys(currentValues), Object.keys(initialValues));
  let expandoObject = Object();
  for (let objectKey of objectKeys) {
    if (!equals(currentValues[objectKey], preJobCheckListInitialState[objectKey])) {
      expandoObject[`${objectKey}`] = cloneDeep(currentValues[objectKey]);
    } else {
      expandoObject[`${objectKey}`] = cloneDeep(initialValues[objectKey]);
    }
  }
  return expandoObject;
};

export const mergePostJobChecklistFunc = (currentValues, initialValues) => {
  if (isEmpty(currentValues)) return initialValues;
  if (isEmpty(initialValues)) return currentValues;

  let objectKeys = union(Object.keys(currentValues), Object.keys(initialValues));
  let expandoObject = Object();
  for (let objectKey of objectKeys) {
    if (!equals(currentValues[objectKey], postJobCheckListInitialValues[objectKey])) {
      expandoObject[`${objectKey}`] = cloneDeep(currentValues[objectKey]);
    } else {
      expandoObject[`${objectKey}`] = cloneDeep(initialValues[objectKey]);
    }
  }
  return expandoObject;
};

export const mergePreJobChecklistFuncV2 = (current, initial) => {
  if (isEmpty(current)) return initial;
  if (isEmpty(initial)) return current;

  const { inspectedServices: currentInspected, ...currentValues } = current ?? {};
  const { inspectedServices: initialInspected, ...initialValues } = initial ?? {};

  let objectKeys = union(Object.keys(currentValues), Object.keys(initialValues));
  let expandoObject = Object();
  for (let objectKey of objectKeys) {
    if (!equals(currentValues[objectKey], preJobCheckListInitialStateV2[objectKey])) {
      expandoObject[`${objectKey}`] = cloneDeep(currentValues[objectKey]);
    } else {
      expandoObject[`${objectKey}`] = cloneDeep(initialValues[objectKey]);
    }
  }

  const currentInspectedServices = currentInspected ?? {};
  const initialInspectedServices = initialInspected ?? {};

  let inspectedKeys = union(Object.keys(currentInspectedServices), Object.keys(initialInspectedServices));
  let expandoInspectedServices = Object();

  for (let objectKey of inspectedKeys) {
    if (!equals(currentInspectedServices[objectKey], inspectedServicesInitialState[objectKey])) {
      expandoInspectedServices[`${objectKey}`] = cloneDeep(currentInspectedServices[objectKey]);
    } else {
      expandoInspectedServices[`${objectKey}`] = cloneDeep(initialInspectedServices[objectKey]);
    }
  }

  expandoObject["inspectedServices"] = expandoInspectedServices;

  return expandoObject;
};

export const JobStatusSection: React.FC<{
  message: string;
  phoneNumber: string;
  status: string;
  jobId: string;
  invoiceId?: string;
  addInvoiceInitialValues: UpdateInvoiceDetailsFormValues;
  contact: Contact;
}> = ({ status, jobId, invoiceId, addInvoiceInitialValues, message, phoneNumber, contact }) => {
  const { state } = useInspectionReport();
  const { state: stateV2 } = useInspectionReportV2();
  const { data } = useQuery<Query, QueryGetJobArgs>(GET_JOB, {
    variables: {
      id: jobId,
    },
    fetchPolicy: "cache-first",
    returnPartialData: true,
  });
  const jobType = data?.getJob?.type;
  const isVioc = jobType === "Vioc Inspection";
  const services = data?.getJob?.services;
  const hasInspection = !!data?.getJob?.preJobCheckListV2;
  const isInspectionViocOnly = isVioc && hasInspection && services.length === 1;
  const isLegacyJob = new Date(data?.getJob?.appointment?.startDate) < new Date("2024/05/12");

  return (
    <Paper square={true}>
      <Box display={"flex"}>
        <Box className="py-2 px-2 w-1/2 flex flex-col justify-center">
          <Chip color={"primary"} label={status} />
        </Box>
        <Box className={"flex-1 flex flex-row justify-end"}>
          {!isLegacyJob && (
            <OpenInspectionButtonV2
              jobId={jobId}
              invoiceId={invoiceId}
              preJobCheckListInitialValues={mergePreJobChecklistFuncV2(
                stateV2,
                addInvoiceInitialValues.preJobCheckListV2 ?? {}
              )}
              contact={contact}
            />
          )}
          {(hasInspection || data?.getJob?.status === "Closed") && (
            <GoToInvoiceButton
              invoiceId={invoiceId}
              initialValues={addInvoiceInitialValues}
              jobId={jobId}
              status={status}
              createDefaultInvoice={isInspectionViocOnly}
            />
          )}
          <JobActionButton
            createdAt={data?.getJob?.appointment?.startDate}
            jobId={jobId}
            jobStatus={status}
            jobType={jobType}
            phoneNumber={phoneNumber}
            message={message}
            invoiceId={invoiceId}
            preJobCheckListInitialValues={mergePreJobChecklistFunc(
              state,
              addInvoiceInitialValues?.preJobCheckList ?? {}
            )}
            preJobChecklistV2InitialValues={mergePreJobChecklistFuncV2(
              stateV2,
              addInvoiceInitialValues.preJobCheckListV2 ?? {}
            )}
            contact={contact}
          />
        </Box>
      </Box>
    </Paper>
  );
};
